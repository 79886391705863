import React from 'react';
import { Container } from '@mui/material';
import PricingBoxes from '../Shared/PricingBoxes';

const Pricing = (props) => {
  return (
    <Container maxWidth="lg">
      <div style={{marginTop: '2rem'}}>
        <PricingBoxes user={props.user} cta="Wechseln" />
      </div>
    </Container>
  );
};

export default Pricing;