import React from 'react';
import { Chip, Paper, Divider, MenuItem, ListItemIcon, ListItemText, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import MoreMenu from '../Shared/MoreMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import NumbersIcon from '@mui/icons-material/Numbers';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import i18n from 'i18next';

const Card = (props) => {

  const isPositive = (props.item.type == 'revenue' && props.item.bill_amount > 0) || (props.item.type == 'expense' && props.item.bill_amount < 0);

  return (
    <Paper className={`react-kanban-card ${props.dragging.dragging ? 'react-kanban-card--dragging' : ''}`}>
      <Stack direction="row" sx={{placeContent: 'space-between'}}>
        <div>
          <p className="text-emphasize"><strong>{props.item.customer?.name ?? props.item.label}</strong></p>
          <p><Tooltip title="Rechnungsnummer"><small className="text-secondary"><NumbersIcon fontSize="small" /> {props.item.number}</small></Tooltip></p>
          { props.item.profit !== undefined && <p><Tooltip title="Reingewinn"><small className='text-secondary'><DataUsageIcon fontSize="small" /> {i18n.t('currency_label', { val: props.item.profit, currency: props.item.currency })}</small></Tooltip></p> }
          { props.item.recurring_until && <p><Tooltip title="Wiederkehrende Zahlung bis"><small className="text-secondary"><EventRepeatIcon fontSize="small" /> {dayjs.unix(props.item.recurring_until).utc().format('DD.MM.YYYY')}</small></Tooltip></p> }
        </div>
        <div>
          <MoreMenu>
            <MenuItem onClick={props.onEdit}>
              <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
              <ListItemText>Bearbeiten</ListItemText>
            </MenuItem>
            <MenuItem onClick={props.onRemove}>
              <ListItemIcon><DeleteForeverIcon fontSize="small" /></ListItemIcon>
              <ListItemText>Entfernen</ListItemText>
            </MenuItem>
          </MoreMenu>
        </div>
      </Stack>
      <Divider />
      <Stack direction="row" sx={{alignItems: 'center !important', placeContent: 'space-between'}}>
        <Tooltip title="Rechnungsdatum"><small className='text-secondary'><EventAvailableIcon fontSize="small" /> {dayjs.unix(props.item.issue_date).utc().format('DD.MM.YYYY')}</small></Tooltip>
        <Chip 
          size="medium"
          label={(isPositive ? '+' : '-') + i18n.t('currency_label', { val: Math.abs(props.item.bill_amount), currency: props.item.currency })} 
          color={isPositive ? 'success' : 'error'} 
        />
      </Stack>
    </Paper>
  );
}

export default Card;