import React from 'react';
import ApexChart from "react-apexcharts";
import i18n from 'i18next';

const BarChart = (props) => {

  let options = {
    chart: {
      stroke: {
        curve: 'smooth'
      },
      toolbar: {
        show: false
      },
      background: 'transparent'
    },
    theme: {
      mode: "dark"
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: props.categories ?? [...Array(props.series.length).keys()],
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return i18n.t('currency_label', { val: value, currency: props.company.default_currency })
        }
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    fill: {
      colors: ['#FFFFFF'],
      opacity: 0.6
    },
    plotOptions: {
      bar: {
        columnWidth: "6px"
      }
    }
  };

  let series = [
    {
      name: props.name,
      data: props.series
    }
  ];

  return (
    <ApexChart
      height={100}
      options={options}
      series={series}
      type="bar"
    />
  );
};

export default BarChart;