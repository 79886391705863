import React from 'react';
import { Stack, Divider } from '@mui/material';

const OrDivider = () => {
  return (
    <Stack direction="row" spacing={2} sx={{alignItems: 'center !important;'}}>
      <Divider sx={{flexGrow: 1}} />
      <small className='text-secondary'>oder</small>
      <Divider sx={{flexGrow: 1}} />
    </Stack>
  );
}

export default OrDivider;