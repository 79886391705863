import React from 'react';
import ApexChart from "react-apexcharts";
import i18n from 'i18next';

const FilledChart = (props) => {

  let options = {
    chart: {
      toolbar: {
        show: false
      },
      background: 'transparent',
      sparkline: {
        enabled: true
      }
    },
    theme: {
      mode: "dark"
    },
    colors: [props.color ?? '#03c9d7'],
    stroke: {
      curve: 'smooth',
      width: 2
    },
    xaxis: {
      categories: props.categories ?? [...Array(props.series.length).keys()],
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return i18n.t('currency_label', { val: value, currency: props.company.default_currency })
        }
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    }
  };

  let series = [
    {
      name: props.name,
      data: props.series
    }
  ];

  return (
    <ApexChart
      height={"90"}
      options={options}
      series={series}
      type="area"
    />
  );
};

export default FilledChart;