import React from 'react';
import { AppBar, Container, Grid } from '@mui/material';
import Logo from '../Shared/Logo';
import { NavLink } from "react-router-dom";

const InfoLayout = (props) => {
  return (
    <div className='info-layout'>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
            <AppBar id="topbar" position="relative">
              <NavLink to={props.user ? props.user.is_preboarded ? "/dashboard" : "/onboarding" : "/"}>
                <Logo />
              </NavLink>
            </AppBar>
          </Grid>
        
          <Grid item xs={12} md={6} className='bg-white'>
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default InfoLayout;