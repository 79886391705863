import React from 'react';
import { Drawer } from '@mui/material';

const Toolbox = (props) => {
  return (
    <Drawer
      variant="temporary"
      id="toolbox"
      anchor='right'
      open={props.isShown}
    >
      <>
      {props.children}
      </>
    </Drawer>
  );
}

export default Toolbox;