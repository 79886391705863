import React from 'react';
import { TableRow, TableCell, MenuItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import MoreMenu from '../Shared/MoreMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import countries from 'react-select-country-list'
import Avatar from '../Shared/Avatar';

const CustomerInfo = (props) => {
  return(
    <TableRow>
      <TableCell>
        <Stack direction="row" spacing={2} sx={{alignItems: 'center !important'}}>
          <Avatar first_name={props.customer.name} />
          <div>
            <p className="text-emphasize"><strong>{props.customer.name}</strong></p>
            <p>{props.customer.vat_id}</p>
          </div>
        </Stack>
      </TableCell>
      <TableCell><p>{props.customer.address}</p><p>{props.customer.zip} {props.customer.city}{props.customer.country_code && `, ${countries().getLabel(props.customer.country_code)}`}</p></TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} sx={{alignItems: 'center !important'}}>
          <span className={`circle-${props.impactFactors[props.customer.impact_factor].color}`} />
          <p className='text-emphasize'><strong><small>{props.impactFactors[props.customer.impact_factor].label}</small></strong></p>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <MoreMenu>
          <MenuItem onClick={props.onEdit}>
            <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
            <ListItemText>Bearbeiten</ListItemText>
          </MenuItem>
          <MenuItem onClick={props.onRemove} disabled={props.customer.is_active}>
            <ListItemIcon><DeleteForeverIcon fontSize="small" /></ListItemIcon>
            <ListItemText>Entfernen</ListItemText>
          </MenuItem>
        </MoreMenu>
      </TableCell>
    </TableRow>
  );
}
export default CustomerInfo;