import React, { useState } from 'react';
import { FormGroup, TextField, Button, Stack, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const ExpenseProperties = (props) => {

  const RECURRING_INTERVALS = [
    { value: 1, label: 'Monatlich' },
    { value: 3, label: 'Vierteljährlich' },
    { value: 6, label: 'Halbjährlich' },
    { value: 12, label: 'Jährlich' }
  ]

  const [isRecurring, setIsRecurring] = useState(props.item.recurring_interval !== undefined && props.item.recurring_interval !== null);

  const didSetIssueDate = (date) => {
    if (!isNaN(date) && date && date.utc() !== undefined) {
      props.onChange({ issue_date: date.utc().unix() });
    } else {
      props.onChange({ issue_date: undefined });
    }
  }

  const didSetRecurringDate = (date) => {
    if (!isNaN(date) && date && date.utc() !== undefined) {
      props.onChange({ recurring_until: date.utc().unix() });
    } else {
      props.onChange({ recurring_until: undefined });
    }
  }

  const didSetRecurring = (isRecurring) => {
    setIsRecurring(isRecurring);
    if (isRecurring) {
      props.onChange({ recurring_interval: RECURRING_INTERVALS[0].value })
    } else {
      props.onChange({ recurring_interval: null, recurring_until: null })
    }
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); props.onNext(); }}>
      <FormGroup>
        <TextField 
          variant="filled"
          label="Zweck / Kommentar" 
          disabled={props.isAnalyzing}
          defaultValue={props.item.label}
          onChange={(e) => props.onChange({ label: e.target.value })} />
      </FormGroup>

      <FormGroup>
        <TextField 
          variant="filled"
          label="Rechnungsnummer" 
          disabled={props.isAnalyzing}
          value={props.item.number}
          onChange={(e) => props.onChange({ number: e.target.value })} />
      </FormGroup>     

      <FormGroup>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <DatePicker
            label="Rechnungsdatum" 
            slotProps={{
              textField: {
                required: true,
                inputProps: { pattern: "[0-9]{2}.[0-9]{2}.[0-9]{4}" }
              },
            }}
            disabled={props.isAnalyzing}
            value={props.item && dayjs.unix(props.item.issue_date).utc()} 
            onChange={didSetIssueDate} />
        </LocalizationProvider>
      </FormGroup>

      <FormGroup>
        <FormControlLabel control={<Checkbox checked={isRecurring} 
                                              disabled={props.isAnalyzing}
                                              onChange={e => didSetRecurring(e.target.checked)} />} 
                                              label="Wiederkehrende Zahlung" />
      </FormGroup>

      { isRecurring && <>
        <FormGroup>
          <TextField
            name="recurring_interval" 
            label="Intervall" 
            defaultValue={props.item.recurring_interval}
            select
            required
            disabled={props.isAnalyzing}
            variant="filled"
            onChange={(e) => props.onChange({ recurring_interval: e.target.value})}
          >
            {RECURRING_INTERVALS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>

        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="Endet am (optional)"
              disabled={props.isAnalyzing}
              minDate={props.item.issue_date && dayjs.unix(props.item.issue_date).utc()}
              value={props.item.recurring_until && dayjs.unix(props.item.recurring_until).utc()} 
              onChange={didSetRecurringDate} />
          </LocalizationProvider>
        </FormGroup>
      </> }

      <Stack direction="row" spacing={1}>
        <Button type="submit" variant="contained" disabled={props.isAnalyzing}>Weiter</Button>
        <Button onClick={props.onBack}>Zurück</Button>
      </Stack>
    </form>
  );
};

export default ExpenseProperties;