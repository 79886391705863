import React from 'react';
import { Card, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import i18n from 'i18next';
import Api from '../../../network/Api';
import DoneIcon from '@mui/icons-material/TaskAlt';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import HealingIcon from '@mui/icons-material/Healing';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

type DeadlineType = "VAT_REPORT" | "INCOME_TAX" | "INSURANCE";
type Deadline = {
  type: DeadlineType,
  due: number,
  amount: number,
  done: boolean
}

const DeadlinesTimeline = (props) => {

  const navigate = useNavigate();
  const shownDeadlines: Array<Deadline> = props.deadlines?.filter((deadline: Deadline) => !deadline.done)?.slice(0,3);
  
  const renderIcon = (type: DeadlineType) => {
    switch(type) {
      case "INCOME_TAX":
        return <ReceiptLongIcon />
      case "INSURANCE":
        return <HealingIcon />
      default:
        return <EventRepeatIcon />
    }
  }

  const done = (deadline: Deadline) => {
    Api.post(`/deadlines`, deadline).then(props.onChange);
  }

  return (
    <Card>
      <h3>Termine &amp; Fristen</h3>

      <Timeline 
        sx={{
          padding: 0,
          marginTop: '0.5rem',
          marginBottom: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
      { shownDeadlines?.length > 0 ? shownDeadlines.map((deadline: Deadline, index) => <TimelineItem>
          <TimelineSeparator>
            { index > 0 && <TimelineConnector /> }
            <TimelineDot>
              { renderIcon(deadline.type) }
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <p className='text-emphasize'><strong>{ i18n.t(`report_types.${deadline.type}`) }</strong></p>
            <Stack direction="row" spacing={1} sx={{alignItems: 'center !important'}}>
              <IconButton size='small' onClick={() => done(deadline)}><Tooltip title="Als erledigt markieren"><DoneIcon fontSize="small" /></Tooltip></IconButton>
              <p className='text-secondary'><small>{dayjs.unix(deadline.due).utc().format('DD. MMMM YYYY')}</small></p>
              { deadline.amount !== undefined && <p className={`${deadline.amount <= 0 ? 'text-success' : 'text-error'}`}><small><strong>{deadline.amount < 0 ? '+' : ''}{i18n.t('currency_label', { val: -1 * deadline.amount, currency: props.company.default_currency })}</strong></small></p> }
            </Stack>
            { deadline.type == "VAT_REPORT" && <Button sx={{ mt: '0.5rem' }} variant='contained' size="small" color="primary" onClick={() => navigate(`/reports/${deadline.due}`)}>{`${props.strategy} Assistent`}</Button> }
          </TimelineContent>
        </TimelineItem>) : <>
        <p><small>Im gewählten Zeitraum sind keine offenen Termine vorhanden.</small></p>
      </>
      }
      </Timeline>
    </Card>
  );
};

export default DeadlinesTimeline;