import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, FormGroup, InputAdornment, Button, Grid } from '@mui/material';
import countries from 'react-select-country-list'
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

export const PasswordForm = (props) => {

  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordConfirmationHasError, setPasswordConfirmationHasError] = useState(false);

  const checkPasswords = (event) => {
    let password = document.getElementById("password")?.value;
    let passwordConfirmation = document.getElementById("password-confirmation")?.value;
    setPasswordHasError(password.length < 8);
    setPasswordConfirmationHasError(password != passwordConfirmation);
    if (props.onChange !== undefined) { props.onChange() }
  }

  return(<>
    <FormGroup>
      <TextField id="password" type="password" name="password" error={passwordHasError} required helperText="Min. 8 Zeichen notwendig." label="Passwort" variant="filled" onChange={checkPasswords} />
    </FormGroup>

    <FormGroup>
      <TextField id="password-confirmation" type="password" name="password_confirmation" error={passwordConfirmationHasError} required helperText="Passwörter müssen übereinstimmen." label="Passwort wiederholen" variant="filled" onChange={checkPasswords} />
    </FormGroup>
  </>);
}

export const CountrySelector = (props) => {

  const selectableCountries = (filter) => {
    let availableCountries = countries().getData()
    if (filter !== undefined) {
      return availableCountries.filter((country) => filter.includes(country.value));
    } else {
      return availableCountries;
    }
  }
  
  return (
    <TextField
      name={props.name} label={props.label} defaultValue={props.defaultValue}
      select
      variant="filled"
      onChange={props.onChange}
      required={props.required}
    >
      {selectableCountries(props.filter).map((country) => (
        <MenuItem key={country.value} value={country.value}>
          {country.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export const VatIdField = (props) => {
  return <TextField 
    name="vat_id" 
    label="UID" 
    variant="filled"
    defaultValue={props.defaultValue} 
    inputProps={{ pattern: "[A-Z]{2}[A-Z][0-9]{8,12}" }}
    onChange={props.onChange}
   />
}

export const TaxRateSelector = (props) => {
  return (
    <TextField
      name={props.name} 
      label={props.label}
      defaultValue={props.defaultValue}
      value={props.value}
      variant="filled"
      select
      onChange={props.onChange}
      required={props.required}
    >
      {props.taxRates.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const TaxItem = (props) => {

  const [item, setItem] = useState({ net_amount: props.item.net_amount, tax_rate_id: props.item.tax_rate_id });
  
  useEffect(() => {
    props.onChange(item);
  }, [item]);

  return (
    <Grid container spacing={2}>
      { props.onRemove && <Grid item xs={2}><Button onClick={props.onRemove} size="small"><RemoveIcon /></Button></Grid> }
      <Grid item xs={ props.onRemove ? 5 : 7 }><FormGroup>
        <TextField
          name={`tax_items[${props.item.key}][net_amount]`} 
          type="number" inputProps={{ step: ".01", min: 0 }} 
          required
          variant="filled"
          label="Netto Betrag" defaultValue={props.item.net_amount}
          onChange={(e) => { 
            setItem(prevState => ({...prevState, net_amount: e.target.value && parseFloat(e.target.value) }));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }} /></FormGroup>
      </Grid>
      <Grid item xs={5}><FormGroup>
        <TaxRateSelector 
          name={`tax_items[${props.item.key}][tax_rate_id]`} 
          label="Steuersatz auf Rechnung"
          taxRates={props.taxRates}
          required
          value={props.item.tax_rate_id} 
          onChange={(e) => { 
            setItem(prevState => ({...prevState, tax_rate_id: e.target.value }));
          }}
        /></FormGroup>
      </Grid>
    </Grid>
  );
}