import React from 'react';
import { FormGroup, Button, Divider, FormControlLabel, Switch, Tooltip, Stack } from '@mui/material';
import { TaxItem } from '../../Shared/FormItems';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import i18n from 'i18next';

class TaxItemsForm extends React.Component {

  constructor(props) {
    super(props);
    this.systemDefaultTaxRate = this.systemDefaultTaxRate.bind(this);
    this.taxRates = this.taxRates.bind(this);
    this.addTaxItem = this.addTaxItem.bind(this);
    this.updateTaxItem = this.updateTaxItem.bind(this);
    this.removeTaxItem = this.removeTaxItem.bind(this);
    this.onChange = this.onChange.bind(this);
  };

  componentDidMount() {
    if (this.props.item.tax_items.length <= 0) {
      this.addTaxItem();
    }
  }

  taxRates() {
    return this.props.item.type == 'revenue' ? this.props.taxRates.revenue : this.props.taxRates.expense
  }

  systemDefaultTaxRate() {
    return this.taxRates().find((taxRate) => { return taxRate.system_default });
  }

  addTaxItem() {
    this.props.onChange({ tax_items: [...this.props.item.tax_items, { key: this.props.item.tax_items.length, net_amount: 0.0, tax_rate_id: this.systemDefaultTaxRate()?.id }]});
  }

  updateTaxItem(item, values) {
    var items = this.props.item.tax_items;
    let index = items.findIndex((e) => e.key == item.key);
    items[index] = {...values, key: item.key};
    this.onChange(items);
  }

  removeTaxItem(item) {
    let items = this.props.item.tax_items;
    let index = items.findIndex((e) => e.key == item.key);
    items.splice(index, 1);
    this.onChange(items);
  }

  onChange(items) {
    var grossAmount = 0.0;
    var billAmount = 0.0;
    var netAmount = 0.0;
    items.forEach((t) => {
      let taxRate = this.taxRates().find((taxRate) => { return taxRate.id == t.tax_rate_id });
      netAmount += t.net_amount;
      billAmount += t.net_amount * (1 + (taxRate?.value ?? 0.0));
      grossAmount += t.net_amount * (1 + ((taxRate?.billed_tax ? taxRate.value : this.systemDefaultTaxRate()?.value) ?? 0.0));
    });

    this.props.onChange({ tax_items: items, bill_amount: billAmount, gross_amount: grossAmount, net_amount: netAmount });
  }

  render() {
    return (
      <>
        { this.props.item.tax_items.map((t,i) => {
          if (t.key === undefined) {
            t.key = i
          }
          return(<TaxItem key={t.key}
                     taxRates={this.taxRates()}
                     item={t}
                     company={this.props.company}
                     onChange={(values) => this.updateTaxItem(t, values)}
                     onRemove={t.key > 0 ? () => { this.removeTaxItem(t) } : undefined} />)
        })}

        { this.props.item.tax_items.length > 0 &&
          <FormGroup>
            <Button sx={{ml: 'auto'}} size="small" onClick={this.addTaxItem}><AddIcon /> Weiterer Steuersatz</Button>
          </FormGroup>
        }

        <Divider />

        <FormGroup sx={{paddingBottom: '0 !important'}}>
          <h3 className='text-center'><strong>Rechnungsbetrag:</strong> <span className={(this.props.item.type == 'revenue' && this.props.item.bill_amount) > 0 || (this.props.item.type == 'expense' && this.props.item.bill_amount < 0) ? "text-success" : "text-error"}>{((this.props.item.type == 'revenue' && this.props.item.bill_amount > 0) || (this.props.item.type == 'expense' && this.props.item.bill_amount < 0) ? '+' : '-')}{i18n.t('currency_label', { val: Math.abs(this.props.item.bill_amount), currency: this.props.company.default_currency})}</span></h3>
          { (this.props.item.type == 'revenue' || this.props.item.gross_amount != this.props.item.bill_amount) && <p className='text-center'>Entstandene Umsatzsteuerschuld: {i18n.t('currency_label', { val: (this.props.item.gross_amount - this.props.item.net_amount), currency: this.props.company.default_currency})}</p>}
          { this.props.item.type == 'expense' && 
            <Stack direction="row" spacing={0.5} sx={{alignItems: 'center !important', justifyContent: 'center'}}>
              <FormControlLabel 
                control={<Switch 
                            name="vat_deductible" 
                            defaultChecked={this.props.item.vat_deductible}
                            onChange={(e) => this.props.onChange({ vat_deductible: e.target.checked })} />} 
                label="Vorsteuer-Abzugsberechtigt"
                sx={{mr: 0}} />
                <Tooltip title="Es muss eine Rechnung vorliegen, auf der deine UID aufscheint.">
                  <HelpOutlineOutlinedIcon className='text-secondary' fontSize="small" />
                </Tooltip>
            </Stack>
          }
        </FormGroup>

        <Divider />
      </>
    );
  }
};

export default TaxItemsForm;