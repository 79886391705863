import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Box } from '@mui/material';
import Toolbox from '../Shared/Toolbox';
import CustomerForm from './Form';
import Api from '../../network/Api';

const CustomerToolbox = (props) => {

  const { customerId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    if (customerId === undefined) { 
      setCustomer(undefined);
      return
    }
    Api.get(`/customers/${customerId}`).then(setCustomer);
  }, [customerId]);

  const cancel = () => {
    props.onCancel()
    navigate('/customers');
  }

  const completion = () => {
    props.completion();
    navigate('/customers');
  }

  return (
    <>
      <Toolbox isShown={pathname == '/customers/new'}>
        <Box sx={{mb: "2rem;"}}>
          <h2>Kunde hinzufügen</h2>
        </Box>
        <CustomerForm 
          method="post" 
          path="/customers" 
          onCancel={cancel} 
          caption="Hinzufügen" 
          completion={completion} />
      </Toolbox>

      <Toolbox isShown={customer !== undefined}>
        {customer !== undefined &&
        <>
          <Box sx={{mb: "2rem;"}}>
            <h2>{customer.name}</h2>
            <p>Kundendaten bearbeiten</p>
          </Box>
          <CustomerForm {...customer} 
            method="put" 
            path={`/customers/${customer.id}`} 
            onCancel={cancel} 
            caption="Speichern" 
            completion={completion} />
        </>}
      </Toolbox>
    </>
  );
};

export default CustomerToolbox;