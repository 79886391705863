import React, { useState } from 'react';
import { Box, FormGroup, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink } from "react-router-dom";
import FormUtils from "../../utils/FormUtils";
import GoogleSignIn from './GoogleSignIn';
import OrDivider from '../Shared/OrDivider';

const LoginForm = (props) => {

  const [isLoading, shouldLoad] = useState(false);

  return (
    <form method="post" action="/login" onSubmit={(e) => { FormUtils.onFormSubmit(e); shouldLoad(true); }}>
      
      <FormGroup>
        <GoogleSignIn />
      </FormGroup>

      <FormGroup sx={{my: '1rem'}}>
        <OrDivider />
      </FormGroup>

      <FormGroup>
        <TextField name="email" type="email" required label="Email" variant="filled" />
      </FormGroup>

      <FormGroup>
        <TextField type="password" name="password" required label="Passwort" variant="filled" />
      </FormGroup>

      <FormGroup row={true} sx={{alignItems: "center"}}>
        {/* <FormControlLabel control={<Checkbox name="remember" value="true" defaultChecked />} label="Remember this device" /> */}
        <Box component="span" ml="auto"><p><small><NavLink to="/login/forgot">Passwort vergessen?</NavLink></small></p></Box>
      </FormGroup>

      <FormGroup>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>Anmelden</LoadingButton>
      </FormGroup>

      <Divider />

      <p className='text-center'>Noch kein Benutzer? <NavLink to="/users/new">Jetzt registrieren</NavLink></p>

    </form>
  );
};

export default LoginForm;