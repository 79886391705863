import React from 'react';
import { NavLink } from "react-router-dom";
import { Grid, Card, Chip, Divider, List, ListItem, ListItemText, ListItemIcon, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import i18n from 'i18next';
import Api from '../../network/Api';

class PricingBoxes extends React.Component {

  constructor(props) {
    super(props);
    this.state = { products: [], isProcessing: false };
    this.purchase = this.purchase.bind(this);
  }

  componentDidMount() {
    Api.get('/products')
      .then((products) => this.setState({products: products}));
  }

  purchase(product_id) {
    this.setState({isProcessing: product_id});
    if (product_id) {
      Api.get(`/products/${product_id}/payment/new`)
        .then((result) => window.location.replace(result.url));
    } else {
      Api.delete('/payment').then((result) => window.location.href = result.url);
    }
  }

  render() {
    return (<>
      <Grid container spacing={4}>
        <PricingBox
          highlight="Für immer gratis"
          features={["1 UVA inkludiert", "Premium Features bei Bedarf freischalten"]}
          cta={this.props.cta}
          isActive={this.props.user && this.state.products?.find((p) => { return p.is_active }) == null}
          isProcessing={this.state.isProcessing}
          onPurchase={this.purchase} />

        <PricingBox
          product={this.state.products[0]}
          highlight="Jederzeit kündbar"
          badge="Bestseller"
          features={["FinanzOnline Assistent", "KI Rechnungserfassung", "Analysen & Insights", "Geld-zurück Garantie*"]}
          isPremium
          cta={this.props.cta}
          isLoading={(this.state.products?.length ?? 0) <= 0}
          isActive={this.props.user && this.state.products[0]?.is_active}
          isProcessing={this.state.isProcessing}
          onPurchase={this.purchase} />

        <PricingBox
          product={this.state.products[1]}
          highlight="In Kürze verfügbar"
          features={["Alle Premium Features", "+ KI Assistent", "+ Forecasting"]}
          isPremium
          cta={this.props.cta}
          isLoading={(this.state.products?.length ?? 0) <= 0}
          isActive={this.props.user && this.state.products[1]?.is_active}
          isProcessing
          onPurchase={this.purchase} />
      </Grid>
      <p style={{marginTop: '2rem'}} className='text-secondary text-center'>* Wenn du nicht zufrieden bist, erhältst du dein Geld zurück. Es gelten die <NavLink to="/terms">Nutzungsbedingungen</NavLink>.</p>
    </>);
  }
};

const PricingBox = (props) => {
  return (
    <Grid item xs={12} md={4}>
      <Card className='pricing-box text-center' sx={{mt: props.badge && !props.isLoading ? '0' : '3rem'}}>
        {props.badge && !props.isLoading && <Chip color="success" label={props.badge} sx={{mb: '1rem'}} />}

        { props.isLoading ? <Skeleton variant="text" sx={{fontSize: '2.5rem'}} /> : <h2 style={{marginTop: '0.5rem'}}>{props.product ? i18n.t('currency_label', { val: props.product.price ?? 0, currency: "eur" }) : "Kostenlos"} {props.product && <small className='text-secondary'><strong> / {props.product.interval == "month" ? "Monat" : "Jahr"}</strong></small>}</h2> }
        
        <Divider />
        
        {props.highlight && !props.isLoading && <><p className='text-success'><strong>{props.highlight}</strong></p><Divider /></>}

        {props.features && ( props.isLoading ? <Skeleton variant="rounded" width="100%" height={180} /> : <>
          <List> 
          {props.features?.map((feature,i) => {
            return <ListItem key={i}><ListItemIcon><CheckOutlinedIcon /></ListItemIcon><ListItemText>{feature}</ListItemText></ListItem>;
          })}
          </List>
        </>)}

        <div style={{marginTop: '1rem'}}>
          { props.isLoading ? <Skeleton variant="rounded" width={150} height={45} /> : <LoadingButton onClick={() => props.onPurchase(props.product?.id)} variant="contained" color="primary" loading={props.isProcessing === props.product?.id} disabled={props.isActive || props.isProcessing !== false}>{props.cta}</LoadingButton> }
        </div>
      </Card>
    </Grid>
  );
}

export default PricingBoxes;