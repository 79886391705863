import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Skeleton, Container } from '@mui/material';
import Landingpage from "./Landingpages/Landingpage";
import Terms from "./Legal/Terms";
import Privacy from "./Legal/Privacy";
import LoginForm from "./Authentication/LoginForm";
import RegistrationForm from "./Authentication/RegistrationForm";
import VerificationForm from "./Authentication/VerificationForm";
import ForgotPwdForm from "./Authentication/ForgotPwdForm";
import ResetPwdForm from "./Authentication/ResetPwdForm";
import Layout from "./Layouts/Layout";
import Onboarding from "./Onboarding/Preboarding";

const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const Board = lazy(() => import('./Board/Board'));
const CompanySettings = lazy(() => import('./Company/Settings'));
const Settings = lazy(() => import('./Settings/Settings'));

import Pricing from "./Settings/Pricing";
import Customers from "./Customers/Customers";
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import locale from '../locales/de.json';

i18n
  .use(initReactI18next)
  .init({
    resources: locale,
    lng: "de"
  });

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.locale('de');

class App extends React.Component {

  constructor(props) {
    super(props);
    this.updateUser = this.updateUser.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.renderLayout = this.renderLayout.bind(this);
    this.renderLazy = this.renderLazy.bind(this);
    this.state = { flash: this.props.flash, user: this.props.user, company: this.props.company };
  }

  updateUser(user) {
    this.setState({ user: user });
  }

  updateCompany(company) {
    this.setState({ company: company });
  }

  renderLayout(props) {
    return <Layout info={props.info} {...this.state}>{ props.lazy ? this.renderLazy(props.view) : props.view }</Layout>
  }

  renderLazy(view) { return (
    <Suspense fallback={
      <Container maxWidth="lg">
        <Skeleton variant="rounded" width={"75%"} height={40} sx={{mb: '1rem'}} />
        <Skeleton variant="rounded" height={180} />
      </Container>
      }>{ view }
    </Suspense>
  )}

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" exact element={<Landingpage countryCode='AT' {...this.state} />} />
          <Route path="/terms" exact element={this.renderLayout({ info: true, view: <Terms /> })} />
          <Route path="/privacy" exact element={this.renderLayout({ info: true, view: <Privacy />})} />

          <Route path="/onboarding" exact element={this.renderLayout({ info: true, view: <Onboarding user={this.state.user} taxRates={this.props.taxRates} /> })} />
          
          <Route path="/dashboard" exact element={this.renderLayout({ lazy: true, view: <Dashboard user={this.state.user} company={this.state.company} />})} />
          <Route path="/reports" exact element={this.renderLayout({ lazy: true, view: <Dashboard user={this.state.user} company={this.state.company} />})} />
          <Route path="/reports/:deadline" exact element={this.renderLayout({ lazy: true, view: <Dashboard user={this.state.user} company={this.state.company} />})} />
          
          <Route path="/cashflow" exact element={this.renderLayout({ lazy: true, view: <Board user={this.state.user} company={this.state.company} taxRates={this.props.taxRates} />})} />
          <Route path="/cashflow/new" exact element={this.renderLayout({ lazy: true, view: <Board user={this.state.user} company={this.state.company} taxRates={this.props.taxRates} />})} />
          <Route path="/revenues/:revenueId/edit" exact element={this.renderLayout({ lazy: true, view: <Board user={this.state.user} company={this.state.company} taxRates={this.props.taxRates} />})} />
          <Route path="/expenses/:expenseId/edit" exact element={this.renderLayout({ lazy: true, view: <Board user={this.state.user} company={this.state.company} taxRates={this.props.taxRates} />})} />
          
          <Route path="/customers" exact element={this.renderLayout({ view: <Customers /> })} />
          <Route path="/customers/new" exact element={this.renderLayout({ view: <Customers /> })} />
          <Route path="/customers/:customerId/edit" exact element={this.renderLayout({ view: <Customers /> })} />
          
          <Route path="/company" exact element={this.renderLayout({ lazy: true, view: <CompanySettings company={this.state.company} taxRates={this.props.taxRates} onUpdate={this.updateCompany} />})} />
          <Route path="/user" exact element={this.renderLayout({ lazy: true, view: <Settings user={this.state.user} onChange={this.updateUser} />})} />
          <Route path="/products" exact element={this.renderLayout({ view: <Pricing user={this.state.user} /> })} />
          
          <Route path="/login" exact element={this.renderLayout({ info: true, view: <LoginForm /> })} />
          <Route path="/users/new" exact element={this.renderLayout({ info: true, view: <RegistrationForm /> })} />
          <Route path="/users/:userId/verification" exact element={this.renderLayout({ info: true, view: <VerificationForm /> })} />
          <Route path="/login/forgot" exact element={this.renderLayout({ info: true, view: <ForgotPwdForm /> })} />
          <Route path="/users/:userId/authentication/:authId/edit" exact element={this.renderLayout({ info: true, view: <ResetPwdForm /> })} />
        </Routes>
      </Router>
    );
  }
}

export default App;