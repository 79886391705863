import React, { useState } from 'react';
import { AppBar, Stack, IconButton, Button } from '@mui/material';
import DropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/MenuOpen';
import UserMenu from './UserMenu';
import Avatar from './Avatar';
import { NavLink } from "react-router-dom";

const Topbar = (props) => {

  const [showsUserMenu, setShowsUserMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const toggleMenu = (event) => {
    setShowsUserMenu(!showsUserMenu);
    setAnchorEl(event.currentTarget);
  }

  return (
    <AppBar id="topbar" position="relative">
      <Stack direction="row" spacing={2} sx={{alignItems: 'center !important'}}>
        <IconButton id="sidebar-mobile-switch" onClick={() => props.toggleMobileSidebar()}>
          <MenuIcon className={"text-secondary"} />
        </IconButton>

        <div style={{marginLeft: 'auto'}}>
          {props.user.is_premium || <Button size="small" variant="outlined" color="secondary" component={NavLink} to="/products">Kostenlose Version</Button>}
        </div>
        
        <Button size="small"
                onClick={toggleMenu}>
          <Avatar {...props.user} /><DropDownIcon />
        </Button>
        <UserMenu user={props.user}
                  anchorEl={anchorEl} 
                  open={showsUserMenu}
                  onClose={toggleMenu} />
      </Stack>
    </AppBar>
  );
}

export default Topbar;