import React from 'react';
import { Stack } from '@mui/material';

const Terms = (props) => {
  return (<Stack spacing={2}>
    <h2>Nutzungsbedingungen für die Buchhaltungs-Software "ProfitDesk"</h2>

<p>Die nachfolgenden Nutzungsbedingungen ("Bedingungen") regeln Ihre Nutzung der Buchhaltungs-Software "ProfitDesk" ("Software"). Bitte lesen Sie diese Bedingungen sorgfältig durch, bevor Sie die Software nutzen. Mit der Registrierung und Nutzung der Software erklären Sie sich mit den folgenden Bedingungen einverstanden:</p>

<h3>1. Allgemeine Nutzungsbestimmungen:</h3>

<p>a. Die Software "ProfitDesk" wurde entwickelt, um selbstständigen UnternehmerInnen bei ihren Buchhaltungstätigkeiten zu unterstützen. Die Nutzung der Software unterliegt diesen Bedingungen sowie den geltenden österreichischen Gesetzen und Vorschriften.</p>

<p>b. Sie verpflichten sich, die Software nicht missbräuchlich zu nutzen und keine rechtswidrigen Handlungen innerhalb der Software vorzunehmen.</p>

<h3>2. Registrierung und Kontozugang:</h3>

<p>a. Sie sind für die Sicherheit Ihres Kontos und Ihres Passworts verantwortlich. Sie stimmen zu, diese Informationen geheim zu halten und uns über jede unbefugte Nutzung Ihres Kontos zu informieren.</p>

<p>b. Bei der Registrierung und Nutzung der Software müssen Sie genaue und aktuelle Informationen angeben.</p>

<h3>3. Datenschutz und Verwendung von Daten:</h3>

<p>a. Ihre persönlichen Daten und Unternehmensinformationen werden gemäß unserer Datenschutzerklärung verarbeitet, um Ihnen den Zugang zur Software und die Nutzung der Dienste zu ermöglichen.</p>

<p>b. Die Software erfasst und speichert auch unternehmerische Daten, die Sie eingeben, um die Buchhaltungstätigkeiten zu unterstützen. Diese Daten werden vertraulich behandelt und in Übereinstimmung mit den geltenden Datenschutzgesetzen verarbeitet.</p>

<h3>4. Haftungsbeschränkung:</h3>

<p>a. Die Software "ProfitDesk" wird "wie besehen" bereitgestellt, ohne ausdrückliche oder stillschweigende Gewährleistungen bezüglich ihrer Richtigkeit, Vollständigkeit oder Eignung für einen bestimmten Zweck.</p>

<p>b. Wir übernehmen keine Haftung für direkte, indirekte oder Folgeschäden, die sich aus der Nutzung der Software oder aus technischen Störungen oder Ausfällen ergeben.</p>

<p>c. Sie sind für die Sicherung und Aufbewahrung Ihrer Daten verantwortlich. Wir haften nicht für den Verlust von Daten, der durch technische Fehler oder andere unvorhersehbare Umstände verursacht wird.</p>

<h3>5. Geld-zurück-Garantie:</h3>

<p>a. Sie haben das Recht, eine Rückerstattung für unverbrauchte Einheiten aus Ihrem im Voraus bezahlten Paket zu beantragen. Ihre Rückerstattungsanfrage muss nachvollziehbare Gründe enthalten.</p>

<p>b. Rückerstattungsanträge müssen schriftlich an office@profitdesk.at innerhalb einer angemessenen Frist eingereicht werden. Bereits verbrauchte Einheiten werden nicht erstattet.</p>

<p>c. Die Geld-zurück-Garantie gilt nicht für einmalig bezahlte Dienstleistungen.</p>

<h3>6. Änderungen der Bedingungen:</h3>

<p>Wir behalten uns das Recht vor, diese Bedingungen jederzeit zu ändern. Geänderte Bedingungen werden auf unserer Website veröffentlicht und treten ab dem Zeitpunkt der Veröffentlichung in Kraft.</p>

<h3>7. Beendigung:</h3>

<p>Wir behalten uns das Recht vor, Ihr Konto und den Zugang zur Software jederzeit zu beenden oder einzuschränken, wenn Sie gegen diese Bedingungen verstoßen oder die Software missbräuchlich nutzen.</p>

<h3>8. Anwendbares Recht:</h3>

<p>Diese Bedingungen unterliegen den Gesetzen der Republik Österreich.</p>
  </Stack>)
}

export default Terms;