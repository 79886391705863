import React, { useState } from 'react';
import FormUtils from "../../utils/FormUtils";
import { Button, FormGroup, TextField, MenuItem, InputAdornment, Grid, FormControlLabel, Switch } from '@mui/material';
import { TaxRateSelector } from "../Shared/FormItems";

const CalculationSettings = (props) => {

  const [didChange, setDidChange] = useState(false);

  const onChange = () => {
    setDidChange(true);
  }

  return (
    <form method="put" action="/company" autoComplete="off" onChange={onChange} onSubmit={(e) => { FormUtils.onFormSubmit(e, props.completion); setDidChange(false); }}>

      <FormGroup>
        <TaxRateSelector 
          name="default_tax_rate_id" 
          onChange={onChange} 
          label="Umsatzsteuersatz" 
          taxRates={props.taxRates.default} 
          defaultValue={props.default_tax_rate_id} />
      </FormGroup>

      <FormGroup>
        <TextField
          name="vat_report_interval" label="Umsatzsteuervoranmeldung Intervall" defaultValue={props.vat_report_interval}
          select
          variant="filled"
          onChange={onChange}
        >
          {props.vat_report_intervals.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          name="income_tax_amount" 
          type="number" inputProps={{ step: ".01", min: 0 }} 
          label="Vierteljährliche Einkommensteuer Vorauszahlung" 
          variant="filled"
          defaultValue={props.income_tax_amount}
          onChange={onChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }} />
      </FormGroup>

      <FormGroup>
        <TextField
          name="insurance_amount" 
          type="number" inputProps={{ step: ".01", min: 0 }} 
          label="Vierteljährliche Sozialversicherungs Vorschreibung" 
          variant="filled"
          defaultValue={props.insurance_amount}
          onChange={onChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }} />
      </FormGroup>

      <FormGroup row={true}>
        <Button sx={{mr: "auto"}} variant="contained" color="primary" type="submit" disabled={!didChange}>Speichern</Button>
      </FormGroup>
    </form>
  );
};

export default CalculationSettings;