import React from 'react';
import { Container, Divider, Grid, Card, Button, FormGroup, TextField, Alert, Skeleton } from '@mui/material';
import { NavLink } from "react-router-dom";
import { PasswordForm } from '../Shared/FormItems';
import FormUtils from "../../utils/FormUtils";
import i18n from 'i18next';
import dayjs from 'dayjs';
import Api from '../../network/Api';

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.didReceiveUser = this.didReceiveUser.bind(this);
    this.onChange = this.onChange.bind(this);
    this.cancelPayment = this.cancelPayment.bind(this);
    this.reactivatePayment = this.reactivatePayment.bind(this);
    this.paymentStatus = this.paymentStatus.bind(this);
    this.state = { user: this.props.user, product: undefined, didChangeUser: false, didChangePassword: false };
  }

  componentDidMount() {
    Api.get('/product').then((product) => this.setState({ product: product }));
  }

  didReceiveUser(user) {
    this.props.onChange(user);
    this.setState({ didChangeUser: false, didChangePassword: false });
  }

  onChange() {
    this.setState({ didChangeUser: true});
  }

  paymentStatus() {
    if (this.state.product?.status == 'unpaid') {
      return <Alert severity="error">Es gab ein Problem mit deiner Zahlung. Bitte bestelle ein neues Permium Paket.</Alert>;
    } else if (this.state.product?.cancel_at) {
      return <Alert severity="warning">Wird am {dayjs.unix(this.state.product?.cancel_at).utc().format('DD. MMMM YYYY')} gekündigt. <a href="#" onClick={(e) => {e.preventDefault; this.reactivatePayment();}}>Kündigung rückgängig machen</a></Alert>;
    } else {
      return <p><strong>Nächste Abrechnung:</strong> {this.state.product?.next_invoice ? dayjs.unix(this.state.product?.next_invoice).utc().format('DD. MMMM YYYY') : '-'}</p>
    }
  }

  cancelPayment() {
    Api.delete('/payment').then(() => { window.location.reload() });
  }
  reactivatePayment() {
    Api.put('/payment').then(() => { window.location.reload() });
  }
  
  render() {
    return (
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Card>
              <FormGroup sx={{mb: "1rem !important;"}}>
                <h2>Mein Paket</h2>
              </FormGroup>

              { this.state.product ? <>
                <h3><strong>{this.state.product?.name}</strong></h3>
                <p><strong>Kosten:</strong> { i18n.t('currency_label', { val: (this.state.product?.price ?? 0.0), currency: "eur" }) }</p>
                { this.paymentStatus() }
                <FormGroup row={true} sx={{mt: "1rem !important;", justifyContent: "flex-start !important"}}>
                  <Button variant="contained" color="primary" component={NavLink} to="/products">Jetzt wechseln</Button>
                  {this.state.user.is_premium && this.state.product && !this.state.product.cancel_at && <Button variant="outlined" color="secondary" onClick={this.cancelPayment}>Premium kündigen</Button>}
                </FormGroup>
              </> : <>
                <Skeleton variant="text" sx={{fontSize: '2.5rem'}} />
                <Skeleton variant="rounded" width="100%" height={100} />
              </> }
            </Card>
          </Grid>
          
          <Grid item xs={12} md={7}>
            <Card>
              <form method="put" action="/user" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, result => {
                if (result.url) {
                  window.location.replace(result.url);
                } else {
                  this.didReceiveUser(result);
                }
              })}}>
                <FormGroup sx={{mb: "1rem !important;"}}>
                  <h3>Benutzerdaten</h3>
                </FormGroup>

                { this.state.user !== undefined && <>
                  <FormGroup row={true}>
                    <TextField name="first_name" required label="Vorname" defaultValue={this.state.user.first_name} variant="filled" onChange={this.onChange} />
                    <TextField name="last_name" required label="Nachname" defaultValue={this.state.user.last_name} variant="filled" onChange={this.onChange} />
                  </FormGroup>

                  <FormGroup>
                    <TextField name="email" type="email" required label="Email" variant="filled" defaultValue={this.state.user.email} onChange={this.onChange} />
                  </FormGroup>

                  <FormGroup row>
                    <Button sx={{mr: "auto"}} variant="contained" color="primary" type="submit" disabled={!this.state.didChangeUser}>Speichern</Button>
                  </FormGroup>
                </> }
              </form>
            
              <Divider sx={{mb: '2rem !important'}} />

              <form method="put" action="/user" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, this.didReceiveUser) }}>
                <FormGroup sx={{mb: "1rem !important;"}}>
                  <h3>Passwort ändern</h3>
                </FormGroup>

                <PasswordForm onChange={() => this.setState({didChangePassword: true})} />

                <FormGroup row>
                  <Button sx={{mr: "auto"}} variant="contained" color="primary" type="submit" disabled={!this.state.didChangePassword}>Ändern</Button>
                </FormGroup>
              </form>
            </Card>
          </Grid>
        </Grid> 
      </Container>
    );
  }
};

export default Settings;