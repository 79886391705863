import React from 'react';
import { Container, Divider, Box, Card, Grid } from '@mui/material';
import CompanyForm from './Form';
import TaxSettings from './TaxSettings';
import CalculationSettings from './CalculationSettings';

const CompanySettings = (props) => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Card>
            <h2 style={{marginBottom: '1rem'}}>{props.company.name}</h2>
            <p><strong>Steuernummer:</strong> {props.company.tax_id}</p>
            <p><strong>Umsatzsteuer-ID:</strong> {props.company.vat_id}</p>
          </Card>
        </Grid>

        <Grid item xs={12} md={7}>
          <Card sx={{mb: '1rem'}}>
            <Box sx={{mb: "2rem;"}}>
              <h3>Stammdaten</h3>
            </Box>

            <CompanyForm {...props.company} completion={props.onUpdate} />

            <Divider sx={{mb: '2rem !important'}} />

            <Box sx={{mb: "2rem;"}}>
              <h3>Steuerdaten</h3>
            </Box>

            <TaxSettings {...props.company} taxRates={props.taxRates} completion={props.onUpdate} />
          
            <Divider sx={{mb: '2rem !important'}} />
            
            <Box sx={{mb: "2rem;"}}>
              <h3>Berechnungsgrundlagen</h3>
            </Box>

            <CalculationSettings {...props.company} taxRates={props.taxRates} completion={props.onUpdate} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanySettings;