import React from 'react';
import { Button } from '@mui/material';
import GoogleIcon from 'images/g-logo.png';

const GoogleSignIn = (props) => {
  return (
    <Button href="/login/google" variant="outlined" startIcon={<img src={GoogleIcon} width="22px" height="22px" />}>Anmelden mit Google</Button>
  );
}

export default GoogleSignIn;