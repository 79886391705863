import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Box, Card, Grid, Fab, Stack, Collapse, ListItem, ListItemText, Tooltip } from '@mui/material';
import Api from '../../network/Api';
import dayjs from 'dayjs';
import i18n from 'i18next';
import TurnoverChart from './Charts/TurnoverChart';
import FilledChart from './Charts/FilledChart';
import BarChart from './Charts/BarChart';
import { YearSelect } from '../Shared/Filters';
import EuroIcon from '@mui/icons-material/Euro';
import ReportBox from './Items/ReportBox';
import DeadlinesTimeline from './Items/DeadlinesTimeline';
import OnboardingSteps from '../Onboarding/OnboardingSteps';
import MoreMenu from '../Shared/MoreMenu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

class Dashboard extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.fetchDashboards = this.fetchDashboards.bind(this);
    this.state = {
      showsOnboarding: false,
      isOnboarded: true,
      onboardingSteps: [],
      year: cookies.get('year') ?? new Date().getFullYear(),
      customers: { count: '-' },
      vat_forecast: undefined,
      pending: { count: '-' },
      expenses: [],
      revenues: [],
      profit: [],
      deadlines: { dues: [], series: [] },
      result: {},
      showsReport: false
    };
  }

  componentDidMount() {
    Api.get('/user').then((user) => this.setState({ isOnboarded: user.is_onboarded, onboardingSteps: user.onboarding_steps, showsOnboarding: !user.is_onboarded }));
    this.fetchDashboards();
  }

  fetchDashboards(year) {
    Api.get(`/reports?year=${year ?? this.state.year}`).then((result) => this.setState(prevState => ({...prevState, ...result})));
    Api.get(`/deadlines?year=${year ?? this.state.year}`).then((result) => this.setState({deadlines: result}));
  }

  changeRateLabel(changeRate) {
    let percent = changeRate * 100;
    return(changeRate != null && <small className={percent >= 0 ? 'text-success' : 'text-error'}><strong>
      {percent >= 0 ? '+' : '-'}{Math.abs(percent).toFixed(1)}% dieses Monat
    </strong></small>);
  }

  finalDuesLabel() {
    return <strong className={this.state.result.final_dues > 0 ? 'text-error' : 'text-success'}>
      { this.state.result.final_dues >= 0 ? "Nachzahlung" : "Rückerstattung"}: {i18n.t('currency_label', { val: this.state.result.final_dues, currency: this.props.company.default_currency }) }
    </strong>
  }

  duesAmount() {
    if (this.state.result.vat === undefined) { return }
    const vat = this.state.result.vat
    const incomeTax = this.state.result.income_tax_amount
    const insuranceAmount = this.state.result.insurance_amount
    return vat + incomeTax + insuranceAmount;
  }

  calculationButton(props) {
    return <Tooltip title="Berechnung"><KeyboardArrowDownIcon {...props} /></Tooltip>
  }

  turnoverInfo() {
    return (
      <MoreMenu icon={this.calculationButton({ className: "text-secondary" })} sx={{top: "-5px"}}>
        { [
          `Einnahmen: ${this.state.result.revenues !== undefined ? i18n.t('currency_label', { val: this.state.result.revenues, currency: this.props.company.default_currency }) : '-'}`,
          `Ausgaben: ${this.state.result.expenses !== undefined ? i18n.t('currency_label', { val: this.state.result.expenses, currency: this.props.company.default_currency }) : '-'}`,
          `Umsatzsteuer: ${this.state.result.vat !== undefined ? i18n.t('currency_label', { val: this.state.result.vat, currency: this.props.company.default_currency }) : '-'}`,
        ].map(due => 
          <ListItem key={due}>
            <ListItemText>
              <p><small>{due}</small></p>
            </ListItemText>
          </ListItem>
        )}
        <ListItem key="total">
          <ListItemText><p><small><strong>Gewinn: {this.state.result.ebit !== undefined ? i18n.t('currency_label', { val: this.state.result.ebit, currency: this.props.company.default_currency }) : '-'}</strong></small></p></ListItemText>
        </ListItem>
      </MoreMenu>
    )
  }

  duesInfo() {
    return (
      <MoreMenu icon={this.calculationButton({ className: "text-white" })} sx={{top: "-5px"}}>
        { [
          `Umsatzsteuer: ${this.state.result.vat !== undefined ? i18n.t('currency_label', { val: this.state.result.vat, currency: this.props.company.default_currency }) : '-'}`,
          `(Abgeführt: ${this.state.result.vat_paid !== undefined ? i18n.t('currency_label', { val: this.state.result.vat_paid, currency: this.props.company.default_currency }) : '-'})`,
          `Sozialversicherung: ${this.state.result.insurance_amount !== undefined ? i18n.t('currency_label', { val: this.state.result.insurance_amount, currency: this.props.company.default_currency }) : '-'}`,
          `(Vorausbezahlt: ${this.state.result.insurance_paid !== undefined ? i18n.t('currency_label', { val: this.state.result.insurance_paid, currency: this.props.company.default_currency }) : '-'})`,
          `Einkommensteuer: ${this.state.result.income_tax_amount !== undefined ? i18n.t('currency_label', { val: this.state.result.income_tax_amount, currency: this.props.company.default_currency }) : '-'}`,
          `(Vorausbezahlt: ${this.state.result.income_tax_paid !== undefined ? i18n.t('currency_label', { val: this.state.result.income_tax_paid, currency: this.props.company.default_currency }) : '-'})`,
        ].map(due => 
          <ListItem key={due}>
            <ListItemText>
              <p><small>{due}</small></p>
            </ListItemText>
          </ListItem>
        )}
        <ListItem key="total">
          <ListItemText><p><small><strong>Total: {this.duesAmount() !== undefined ? i18n.t('currency_label', { val: this.duesAmount(), currency: this.props.company.default_currency }) : '-'}</strong></small></p></ListItemText>
        </ListItem>
      </MoreMenu>
    )
  }

  finalDuesInfo() {
    return (
      <MoreMenu icon={this.calculationButton({ className: "text-secondary" })} sx={{top: "-5px"}}>
        { [
          `Umsatzsteuer: ${i18n.t('currency_label', { val: this.state.result.vat - this.state.result.vat_paid, currency: this.props.company.default_currency })}`,
          `Sozialversicherung: ${i18n.t('currency_label', { val: this.state.result.insurance_amount - this.state.result.insurance_paid, currency: this.props.company.default_currency })}`,
          `Einkommensteuer: ${i18n.t('currency_label', { val: this.state.result.income_tax_amount - this.state.result.income_tax_paid, currency: this.props.company.default_currency })}`,
        ].map(due => 
          <ListItem key={due}>
            <ListItemText>
              <p><small>{due}</small></p>
            </ListItemText>
          </ListItem>
        )}
        <ListItem key="total">
          <ListItemText><p><small><strong>Total: { i18n.t('currency_label', { val: this.state.result.final_dues, currency: this.props.company.default_currency } ) }</strong></small></p></ListItemText>
        </ListItem>
      </MoreMenu>
    )
  }

  render() {
    return (
      <Container id="dashboard" maxWidth="lg">

        <Collapse in={this.state.showsOnboarding}>
          { !this.state.isOnboarded && <Box sx={{mb: '2rem'}}><OnboardingSteps steps={this.state.onboardingSteps} onClose={() => this.setState({ showsOnboarding: false })} /></Box> }
        </Collapse>

        <Stack direction="row" spacing={1} sx={{mb: '2rem'}}>
          <h2>Geschäftsjahr</h2>
          <YearSelect value={this.state.year} onChange={ (year) => {
            this.setState({ year: year });
            this.fetchDashboards(year);
          }} />
        </Stack>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card>
                  <Stack direction="row" sx={{placeContent: 'space-between'}}>
                    <h3>Jahresergebnis</h3>
                    <Fab color="primary" size="medium" sx={{cursor: 'default'}}><EuroIcon /></Fab>
                  </Stack>
                  <p className='text-lg text-emphasize'>{this.state.result.profit !== undefined && this.state.result.profit !== null ? i18n.t('currency_label', { val: this.state.result.profit, currency: this.props.company.default_currency }) : '-'}</p>
                  { this.state.result.final_dues !== undefined && <Stack direction="row" spacing={0}>
                    <p className='text-secondary'>{ this.finalDuesLabel() }</p>
                    { this.finalDuesInfo() }
                  </Stack> }
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <h3 style={{marginBottom: '0.5rem'}}>Tagesaktueller Gewinn</h3>
                  <Stack direction="row" spacing={1} sx={{alignItems: 'center !important'}}>
                    <h2 className='text-light'>{this.state.result.ebit !== undefined && this.state.result.ebit !== null ? i18n.t('currency_label', { val: this.state.result.ebit, currency: this.props.company.default_currency }) : '-'}</h2>
                    <p className='text-secondary'>{ this.state.change_rates?.ebit !== undefined && this.changeRateLabel(this.state.change_rates.ebit) }</p>
                  </Stack>
                  <div className='area-chart'>{<FilledChart series={this.state.profit.map(profit => profit.ebit)} categories={dayjs.months()} name="Gewinn (USt.-bereinigt)" company={this.props.company} />}</div>
                </Card>
                {/* <Card>
                  <Stack direction="row" sx={{placeContent: 'space-between'}}>
                    <h3>Kunden</h3>
                    <Fab color="primary" size="medium" component={NavLink} to="/customers"><ApartmentIcon /></Fab>
                  </Stack>
                  <p className='text-lg text-emphasize'>{this.state.customers.count}</p>
                  <p className='text-secondary'>Gesamt</p>
                </Card> */}
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <Stack direction="row" spacing={0}>
                    <h2><strong>Einnahmen / Ausgaben</strong></h2>
                    { this.turnoverInfo() }
                  </Stack>
                  <TurnoverChart 
                    company={this.props.company} 
                    revenues={this.state.revenues} 
                    expenses={this.state.expenses} 
                    categories={dayjs.months()} />
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <DeadlinesTimeline 
                  company={this.props.company}
                  strategy={this.state.strategy}
                  deadlines={this.state.deadlines.dues}
                  onChange={() => this.fetchDashboards()} />
              </Grid>

              <Grid item xs={12}>
                <Card className='bg-secondary'>
                  <Stack direction="row" spacing={0}>
                    <h3 className='text-light text-white' style={{opacity: 0.7}}>Abgaben</h3>
                    { this.duesInfo() }
                  </Stack>
                  <h2 className='text-light text-white'>
                    { this.duesAmount() !== undefined ? i18n.t('currency_label', { val: this.duesAmount(), currency: this.props.company.default_currency }) : '-' }
                  </h2>
                  <div className='area-chart'>{<BarChart series={this.state.deadlines.series} categories={dayjs.months()} name="Abgaben" color='#fb9678' company={this.props.company} />}</div>
                </Card>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <ReportBox company={this.props.company} />

      </Container>
    );
  }
};

export default withCookies(Dashboard);