import React from 'react';
import ApexChart from "react-apexcharts";
import i18n from 'i18next';

const TurnoverChart = (props) => {

  let options = {
    chart: {
      toolbar: {
        show: false
      },
      background: 'transparent'
    },
    theme: {
      mode: "dark"
    },
    colors: ["#03c9d7", "#fb9678"],
    fill: {
      opacity: 1,
      type: 'solid'
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      labels: {
        useSeriesColors: true
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end',
        columnWidth: "60%"
      },
    },
    stroke: {
      show: true,
      width: 5,
      lineCap: "butt",
      colors: ["transparent"]
    },
    xaxis: {
      categories: props.categories ?? [...Array(props.revenues.length).keys()],
      labels: {
        style: {
          colors: '#34476766'
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: '#34476766'
        },
        formatter: function (value) {
          return i18n.t('currency_label', { val: value, currency: props.company.default_currency })
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      strokeDashArray: 2
    }
  };

  let series = [
    {
      name: "Einnahmen",
      data: props.revenues.map((i) => i.bill_amount)
    },
    {
      name: "Ausgaben",
      data: props.expenses.map((i) => i.bill_amount)
    }
  ];

  return (
    <ApexChart
      height={333}
      options={options}
      series={series}
      type="bar"
    />
  );
};

export default TurnoverChart;