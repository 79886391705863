import Api from '../network/Api';

export default class FormUtils {
  static onFormSubmit(event, completion) {
    event.preventDefault();
    let form = event.target;
    let path = form.getAttribute('action');
    var method = form.getAttribute('method');
    if (method == 'patch') {
      method = 'put';
    }
    
    if (completion) {
      var params = {};
      [...form.getElementsByTagName('input')].forEach(input => {
        let arrayRegex = /\[[^\]]*]/g;
        let array = input.name.match(arrayRegex);
        
        if (array && array.length > 1) {
          let i = array[0].replace('[', '').replace(']', '');
          let key = array[1].replace('[', '').replace(']', '');
          let inputName = input.name.replace(arrayRegex, '');
          if (params[inputName] === undefined) {
            params[inputName] = [];
          }
          if (params[inputName].length <= i) {
            params[inputName].push({});
          }
          params[inputName][i][key] = input.value;

        } else {
          params[input.name] = input.value;
        }
      });
      Api.request(path, method, params).then(completion);
    } else {
      let csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
      this.appendHiddenField(form, "authenticity_token", csrfToken);
      if (method == 'put') {
        this.appendHiddenField(form, "_method", "put");
        form.setAttribute('method', 'post');
      }
      form.submit();
    }
  };
  
  static appendHiddenField(form, name, value) {
    let hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = name;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  };
};