import React from 'react';
import Api from '../../../network/Api';
import Autocomplete from '@mui/material/Autocomplete';
import { FormGroup, TextField, Button, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

class RevenueProperties extends React.Component {

  constructor(props) {
    super(props);
    this.didSetIssueDate = this.didSetIssueDate.bind(this);
    this.state = { customers: [] };
  };

  componentDidMount() {
    Api.get("/customers").then((customers) => {
      this.setState({ customers: customers });
    });
  }

  didSetIssueDate(date) {
    if (!isNaN(date) && date && date.utc() !== undefined) {  
      this.props.onChange({ issue_date: date.utc().unix() }); 
    } else {
      this.props.onChange({ issue_date: undefined }); 
    }
  }

  render() {
    return (
      <form onSubmit={(e) => { e.preventDefault(); this.props.onNext(); }}>
        <FormGroup>
          <Autocomplete
            freeSolo
            options={this.state.customers}
            defaultValue={this.props.item.customer?.name}
            getOptionLabel={(entity) => entity.name ?? entity}
            onChange={(event, entity, reason, details) => { this.props.onChange({ customer: entity }) } }
            disabled={this.props.isAnalyzing}
            renderInput={(params) => (
              <TextField {...params} 
                variant="filled"
                label="Kunde"
                required
                onChange={(e) => this.props.onChange({ customer: { name: e.target.value } })} />
            )}
          />
        </FormGroup>

        <FormGroup>
          <TextField 
            variant="filled"
            required 
            disabled={this.props.isAnalyzing}
            label="Rechnungsnummer" 
            value={this.props.item.number}
            onChange={(e) => this.props.onChange({ number: e.target.value })} />
        </FormGroup>        

        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker 
              slotProps={{
                textField: {
                  required: true,
                  inputProps: { pattern: "[0-9]{2}.[0-9]{2}.[0-9]{4}" }
                },
              }} 
              disabled={this.props.isAnalyzing}
              label="Rechnungsdatum" 
              value={this.props.item && dayjs.unix(this.props.item.issue_date).utc()} 
              onChange={this.didSetIssueDate} />
          </LocalizationProvider>
        </FormGroup>
        
        <Stack direction="row" spacing={1}>
          <Button type="submit" variant="contained" disabled={this.props.isAnalyzing}>Weiter</Button>
          <Button onClick={this.props.onBack}>Zurück</Button>
        </Stack>
      </form>
    );
  }
};

export default RevenueProperties;