import React, { useRef } from 'react';
import { NavLink } from "react-router-dom";
import { Container, AppBar, Button, Stack, Grid, Card, CardMedia } from '@mui/material';
import PricingBoxes from '../../Shared/PricingBoxes';
import FeatureForecastingImage from 'images/feature-forecasting.jpg';
import FeatureAssistantImage from 'images/feature-assistant.jpg';
import FeatureInsightsImage from 'images/feature-insights.jpg';
import FeatureFinancialsImage from 'images/feature-financials.jpg';
import FeatureDashboardImage from 'images/dashboard.jpg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from '../../Shared/Logo';
import ConsentScripts from '../../../utils/ConsentScripts';

const Landingpage = (props) => {

  const features = useRef(null);
  const featureReports = useRef(null);
  const featureCashflow = useRef(null);
  const featureCustomers = useRef(null);
  const featureAi = useRef(null);

  const scrollTo = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const featureContent = (props) => {
    return (<>
      <h2 style={{marginBottom: '1rem'}}>{props.title}</h2>
      <p className='text-secondary' dangerouslySetInnerHTML={{__html: props.description}} />        
    </>)
  }

  const featureCard = (props) => {
    return <div style={{textAlign: 'center'}}><img className='feature-card' src={props.cover} alt={props.info} /></div>;
  }

  return (
    <div id="landingpage">

      <ConsentScripts {...props} />

      <Container maxWidth="lg">
        <AppBar id="topbar" position="relative">
          <Stack direction="row" spacing={2}>
            <div style={{marginRight: 'auto'}}><Logo /></div>
            <Button sx={{ display: { xs: 'none', md: 'inline flex' }}} size="small" component={NavLink} to="/users/new">Registrieren</Button>
            <Button size="small" variant="outlined" component={NavLink} to="/login">Zum Dashboard</Button>
          </Stack>
        </AppBar>
      </Container>

      <div id="content">
        <section style={{padding: '4rem 0'}}>
          <Container maxWidth="lg">
            <Grid container sx={{alignItems: 'center'}} spacing={6}>
              <Grid item xs={12} lg={7}>
                <Stack spacing={3}>
                  <h1>Finanz Dashboard für <span className='text-success'>Einzelunternehmen</span></h1>
                  <Stack direction="row" spacing={2}>
                    <Button component={NavLink} to="/users/new" variant="contained" color="primary" endIcon={<ChevronRightIcon />}>Jetzt loslegen</Button>
                    <Button sx={{ display: { xs: 'none', md: 'inline flex' }}} onClick={() => scrollTo(features)} endIcon={<ChevronRightIcon />}>Mehr erfahren</Button>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={5}>
                <img 
                  id="landingpage-cover" 
                  alt="Deine Einnahmen & Ausgaben immer im Blick"
                  width="100%" height="100%" 
                  src={FeatureDashboardImage} />
              </Grid>
            </Grid>
          </Container>
        </section>

        <section ref={features} className='bg-white'>
          <Container maxWidth="lg">
            <Grid container rowSpacing={12} columnSpacing={12} sx={{alignItems: 'center'}}>
              <Grid ref={featureCustomers} item xs={12} md={6}>
                { featureContent({ title: "Forecasting",
                                   description: "Zieht Rückschlüsse aus deinen Finanzströmen und leitet daraus deine zukünftige Geschäftstätigkeit und -fähigkeit ab. Dadurch behältst du immer einen Überblick darüber, was dich im laufenden Geschäftsjahr bzw. zum Ende hin finanziell erwartet. Das <strong class='text-underline'>beugt unschöne finanzielle Überraschungen vor</strong>." }) }
              </Grid>
              <Grid item xs={12} md={6}>
                { featureCard({ cover: FeatureForecastingImage, label: 'Kundenverhalten analysieren', info: 'Wiederkehrende Zahlungen und Umsatzsteigerungen identifizieren.' }) }
              </Grid>

              <Grid item xs={12} md={6} sx={{display: { xs: 'none', md: 'block' }}}>
                { featureCard({ cover: FeatureAssistantImage, label: 'FinanzOnline Assistent', info: 'UVA automatisch generieren und an FinanzOnline übermitteln.' }) }
              </Grid>
              <Grid ref={featureReports} item xs={12} md={6}>
                { featureContent({ title: "Virtueller Assistent", 
                                   description: "Im Dashboard werden die bevorstehenden Fristen mit den jeweils fälligen Steuerbeträgen immer tagesaktuell angezeigt. Mit dem FinanzOnline Assistent werden dir allfällige Erklärungen an das Finanzamt intelligent vor-generiert und als Datenstrom zur Verfügung gestellt. Das <strong class='text-underline'>spart zusätzlich Kosten und Zeit</strong>." }) }
              </Grid>
              <Grid item xs={12} md={6} sx={{display: { md: 'none' }}}>
                { featureCard({ cover: FeatureAssistantImage, label: 'FinanzOnline Assistent', info: 'UVA automatisch generieren und an FinanzOnline übermitteln.' }) }
              </Grid>

              <Grid ref={featureCashflow} item xs={12} md={6}>
                { featureContent({ title: "Automatisiertes Finanzmanagement", 
                                   description: "Deine Umsätze, Gewinne, Steuerschulden/-gutschriften sowie allenfalls offene Forderungen werden immer in Echtzeit ermittelt. Etwaige Sozialversicherungs-Beiträge sowie Einkommensteuer und Gewinnfreibeträge werden dabei berücksichtigt. So weißt du immer, woran du bist. Das <strong class='text-underline'>sorgt für mehr Unternehmensstabilität</strong>." }) }
              </Grid>
              <Grid item xs={12} md={6}>
                { featureCard({ cover: FeatureInsightsImage, label: 'Künstliche Intelligenz', info: 'Rechnungen & Belege abfotografieren und auswerten lassen.' }) }
              </Grid>

              <Grid item xs={12} md={6} sx={{display: { xs: 'none', md: 'block' }}}>
                { featureCard({ cover: FeatureFinancialsImage, label: 'E/A Berechnung in Echtzeit', info: 'Gewinne & Steuern schnell und unkompliziert ermitteln.'}) }
              </Grid>
              <Grid ref={featureAi} item xs={12} md={6}>
                { featureContent({ title: "Analysen & Geschäfts-Insights", 
                                   description: "Du wirst auf Geschäfts-spezifische Steuer-Fallen und Nachzahlungen hingewiesen, die man im Alltag nicht immer am Schirm hat. Darüber hinaus wird das Zahlverhalten deiner Kunden analysiert und deren jeweiligen finanziellen Einfluss auf dein Unternehmen dargestellt. Das <strong class='text-underline'>führt zur Erkenntnis über Chancen/Risiken und Wachstumspotenzial</strong>." }) }
              </Grid>
              <Grid item xs={12} md={6} sx={{display: { md: 'none' }}}>
                { featureCard({ cover: FeatureFinancialsImage, label: 'E/A Berechnung in Echtzeit', info: 'Gewinne & Steuern schnell und unkompliziert ermitteln.'}) }
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className='text-center'>
          <Container maxWidth="lg">
            <h2 style={{marginBottom: '1rem'}}>Angebot &amp; Preise</h2>
            <p className='text-secondary' style={{marginBottom: '2rem'}}>Wähle aus einem der nachstehenden Pakete oder wechsle auch jederzeit innerhalb der Anwendung. Für alle Premium Pakete gilt die Geld-zurück Garantie*.</p>
            <PricingBoxes user={props.user} cta="Auswählen" />
          </Container>
        </section>

        <section className='bg-dark text-center'>
          <Container maxWidth="lg">
            <p className='text-white text-lg'>ProfitDesk <span className='text-underline'>erspart dir</span> wertvolle Zeit und Nerven mit den <span className='text-underline'>Geschäftsfinanzen</span>.</p>
            <Button sx={{mt: '2rem'}} component={NavLink} to="/users/new" variant="contained" color="primary">Jetzt kostenlos starten</Button>
          </Container>
        </section>

        <section id="footer">
          <Container maxWidth="lg">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={3}>
                <Logo />
                <p>&copy; 2023 ProfitDesk.</p>
              </Grid>
              <Grid item xs={12} sm={3}>
                <h3>Themen</h3>
                <p><small>Umsatzsteuervoranmeldung erstellen</small></p>
                <p><small>SVS Beitrag berechnen</small></p>
                <p><small>Gewinnfreibetrag ermitteln</small></p>
                <p><small>Einkommensteuer nebenerwerbstätig</small></p>
                <p><small>Finanzamt Nachzahlung</small></p>
              </Grid>
              <Grid item xs={12} sm={3}>
                <h3>Kontakt</h3>
                <p><small><NavLink to="/terms">Nutzungsbedingungen</NavLink></small></p>
                <p><small><NavLink to="/privacy">Datenschutzerklärung</NavLink></small></p>
                <p><small>Support Chat: <a id="open-chat" href="#open-chat" onClick={() => {window.HubSpotConversations.widget.load({ widgetOpen: true });}}><strong>Öffnen</strong></a></small></p>
                <p><small>Email: office@profitdesk.at</small></p>
              </Grid>
              <Grid item xs={12} sm={3}>
                <h3>Impressum</h3>
                <p><small>Inhaber: DI Michael Maier, MBA</small></p>
                <p><small>Firmensitz: Schubertstraße 6a<br />8010 Graz, Österreich</small></p>
                <p><small>Rechtsform: Einzelunternehmen</small></p>
                <p><small>UID: ATU78245837</small></p>
              </Grid>
            </Grid>

            <p style={{marginTop: '2rem'}} className='text-center'><small>Personenbezogene Bezeichnungen beziehen sich auf Männer und Frauen in gleicher Weise.</small></p>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Landingpage;