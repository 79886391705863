import React from 'react';
import { MenuList, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import GraphIcon from '@mui/icons-material/AutoGraphOutlined';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {
  return (
    <MenuList>
      <MenuItem component={NavLink} to="/dashboard">
        <ListItemIcon><GraphIcon /></ListItemIcon>
        <ListItemText primary="Dashboard" />
      </MenuItem>

      <MenuItem onClick={() => props.onToggle(true)} component={NavLink} to="/cashflow">
        <ListItemIcon><ViewKanbanOutlinedIcon /></ListItemIcon>
        <ListItemText primary="Cashflow" />
      </MenuItem>

      <MenuItem component={NavLink} to="/customers">
        <ListItemIcon><Diversity2OutlinedIcon /></ListItemIcon>
        <ListItemText primary="Kunden Insights" />
      </MenuItem>

      <MenuItem component={NavLink} to="/company">
        <ListItemIcon><TuneOutlinedIcon /></ListItemIcon>
        <ListItemText primary="Einstellungen" />
      </MenuItem>
    </MenuList>
  );
};

export default Sidebar;