import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Navigate } from "react-router-dom";
import Kanban from '../Kanban';
import Card from './Card';
import { Container, Stack, Grid, TextField, Button } from '@mui/material';
import Api from '../../network/Api';
import { YearSelect, QuarterFilter } from '../Shared/Filters';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import i18n from 'i18next';
import CashflowToolbox from './Toolbox';
import AddIcon from '@mui/icons-material/Add';

const board = {
  columns: []
}

class Board extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    dayjs.extend(quarterOfYear);
    this.fetchBoard = this.fetchBoard.bind(this);
    this.filterBoard = this.filterBoard.bind(this);
    this.filterQuarters = this.filterQuarters.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.didUpdateItem = this.didUpdateItem.bind(this);
    this.closeToolbox = this.closeToolbox.bind(this);
    this.onCardDrag = this.onCardDrag.bind(this);
    this.removeItemLocally = this.removeItemLocally.bind(this);
    const { cookies } = props;
    this.state = {
      year: cookies.get('year') ?? new Date().getFullYear(),
      board: board,
      search: '',
      filteredBoard: board,
      showsCreateForm: false,
      editRevenue: undefined,
      editExpense: undefined
    };
  }

  componentDidMount() {
    this.fetchBoard();
  }

  fetchBoard(year) {
    Api.get(`/board?year=${year ?? this.state.year}`)
      .then(this.filterBoard);
  }

  filterQuarters(board) {
    let quarters = this.props.cookies.get('quarters');
    return quarters !== undefined ? {...board, 
      columns: board.columns.map(columns => { 
        return {...columns, 
                  cards: columns.cards.filter(card => { 
                    return (card.recurring_interval && (!card.recurring_until || dayjs.unix(card.recurring_until).utc() >= dayjs().utc())) || quarters.includes(dayjs.unix(card.issue_date).utc().quarter().toString());
                  })} 
      })} : board;
  }

  filterSearch(board) {
    let search = this.state.search;
    return search !== '' ? {...board, 
      columns: board.columns.map(columns => { 
        return {...columns, 
                  cards: columns.cards.filter(card => { 
                    return card.label?.search(search) > -1 || card.customer?.name?.search(search) > -1 || card.number?.search(search) > -1;
                  })} 
      })} : board;
  }

  filterBoard(board) {
    let filtered = this.filterQuarters(board);
    let searched = this.filterSearch(filtered);
    this.setState({ board: board, filteredBoard: searched });
  }

  onCardDrag(card, from, to) {
    if (from.fromColumnId != to.toColumnId) { return }

    var board = this.removeItemLocally(card);
    let newColumnIndex = board.columns.findIndex((c) => c.id == to.toColumnId);
    board.columns[newColumnIndex].cards.splice(to.toPosition, 0, card);
    this.filterBoard(board);

    let boardOrder = board.columns.map((column) => { return { id: column.id, cards: column.cards.map((card) => { return { id: card.id, type: card.type } } ) } });
    Api.put("/board", { columns: boardOrder });
  }

  didUpdateItem(item) {
    this.fetchBoard();
    this.closeToolbox();
  }

  closeToolbox() {
    this.setState({ showsCreateForm: false, editRevenue: undefined, editExpense: undefined });
  }

  removeItem(item) {
    if (confirm("Möchtest du diesen Zahlungsfluss permanent entfernen?") == true) {
      this.removeItemLocally(item);
      Api.delete(`/${item.type == 'revenue' ? 'revenues' : 'expenses'}/${item.id}`)
    }
  }

  removeItemLocally(item) {
    var board = this.state.board;
    let existingColumnIndex = board.columns.findIndex((c) => c.id == item.column);
    let existingItemIndex = board.columns[existingColumnIndex].cards.findIndex((c) => c.id == item.id);
    board.columns[existingColumnIndex].cards.splice(existingItemIndex, 1);
    this.filterBoard(board);
    return board;
  }

  render() {
    return(
      <Container maxWidth="xl">

        <Grid container sx={{mb: '2rem', alignItems: 'center !important'}} spacing={2}>
          <Grid item>
            <Stack direction="row" spacing={1}>
              <h2>Cashflow</h2>
              <YearSelect value={this.state.year} onChange={ (year) => {
                this.setState({ year: year });
                this.fetchBoard(year);
              }} />
            </Stack>
          </Grid>
          <Grid item>
            <QuarterFilter onChange={() => this.filterBoard(this.state.board)} />
          </Grid>
          <Grid item>
            <TextField label="Durchsuchen" size="small" onChange={(e) => {
              this.setState({ search: e.target.value }, () => this.filterBoard(this.state.board))
            }} />
          </Grid>
          <Grid item sx={{ ml: { md: 'auto' }}}>
          <Button variant="contained" color="primary" onClick={() => {this.setState({showsCreateForm: true})}} startIcon={<AddIcon />}>Neuer Zahlungsfluss</Button>
          { this.state.showsCreateForm && <Navigate to="/cashflow/new" /> }
          { this.state.editRevenue && <Navigate to={`/revenues/${this.state.editRevenue.id}/edit`} /> }
          { this.state.editExpense && <Navigate to={`/expenses/${this.state.editExpense.id}/edit`} /> }
            <CashflowToolbox {...this.props} onCancel={this.closeToolbox} completion={this.didUpdateItem} />
          </Grid>
        </Grid>

        <Kanban 
          disableColumnDrag
          onCardDragEnd={this.onCardDrag}
          renderColumnHeader={(column) =>           
            <div className="react-kanban-column-header"><span>{i18n.t(`boards.${column.id}`)}</span></div>
          }
          renderCard={(item, dragging) => 
            <Card 
              item={item} 
              dragging={dragging} 
              onEdit={() => this.setState(item.type == 'revenue' ? { editRevenue: item } : { editExpense: item })} 
              onRemove={() => this.removeItem(item)}
            />
          }
        >{this.state.filteredBoard}</Kanban>
      </Container>
    );
  }
}

export default withCookies(Board);