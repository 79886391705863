import React, { useState } from 'react';
import { Menu, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MoreMenu = (props) => {
  
  const [showsMenu, updateShowsMenu] = useState(false);
  const [anchorEl, updateAnchorEl] = useState(undefined);

  const toggleMenu = (e) => {
    updateShowsMenu(!showsMenu);
    updateAnchorEl(e.target);
  }

  return(
    <>
      <IconButton 
        size="small" 
        onClick={toggleMenu}
        sx={props.sx}
      >{props.icon ? props.icon : <MoreVertIcon />}</IconButton>

      <Menu
        open={showsMenu}
        onClick={toggleMenu}
        onClose={toggleMenu}
        anchorEl={anchorEl}
      >
        {props.children}
      </Menu>
    </>
  );
}
export default MoreMenu;