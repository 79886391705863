import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Toolbox from '../../Shared/Toolbox';
import Report from '../../Reports/Report';

const ReportBox = (props) => {

  const { deadline } = useParams();
  const navigate = useNavigate();

  return (
    <Toolbox isShown={deadline !== undefined}>
      {deadline !== undefined && <Report deadline={deadline} company={props.company} completion={() => { navigate('/dashboard'); }} />}
    </Toolbox>
  );
};

export default ReportBox;