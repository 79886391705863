import React from 'react';
import { NavLink } from "react-router-dom";
import { Menu, MenuItem, ListItem, ListItemIcon, ListItemText, Divider, Paper, Button } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const UserMenu = (props) => {
  return(
    <Menu
      id="user-menu"
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
    >
      <ListItem>
        <h3><strong>Hi,</strong> {props.user.first_name}</h3>
      </ListItem>

      {props.user?.is_premium ? <Divider /> : <><Divider />
        <ListItem>
          <Paper elevation={0} className='bg-info' sx={{padding: '1rem'}}>
            <p className='text-emphasize'><strong>Jetzt zu Premium wechseln</strong></p>
            <p><small>und 20% Rabatt auf jährliche Zahlung sichern.</small></p>
            <Button sx={{mt: '1rem'}} component={NavLink} to="/products" size="small" variant="contained" color="info">Zum Angebot</Button>
          </Paper>
        </ListItem>

        <Divider />
      </>}
      
      <MenuItem component={NavLink} to="/user" onClick={props.onClose}>
        <ListItemIcon><SettingsOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Account Einstellungen</ListItemText>
      </MenuItem>
      <MenuItem component="a" href="/logout">
        <ListItemIcon><LogoutOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Abmelden</ListItemText>
      </MenuItem>
    </Menu>
  );
}
export default UserMenu;