import React from 'react';
import { Container, Card, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Button, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Navigate } from "react-router-dom";
import Api from '../../network/Api';
import CustomerInfo from './CustomerInfo';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreMenu from '../Shared/MoreMenu';
import CustomerToolbox from './Toolbox';

class Customers extends React.Component {

  static IMPACT_FACTORS = [
    { label: "Inaktiv", color: 'secondary', info: "Kunde hat noch keine Rechnungen erhalten" }, 
    { label: "Unregelmäßig", color: 'error', info: "Kunde hat die ersten Rechnungen erhalten" },
    { label: "Regelmäßig", color: 'warning', info: "Kunde bringt ein regelmäßiges Einkommen" },
    { label: "Signifikant", color: 'success', info: "Kunde trägt einen erheblichen Beitrag zum Umsatz" }
  ];

  constructor(props) {
    super(props);
    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.didUpdateCustomer = this.didUpdateCustomer.bind(this);
    this.closeToolbox = this.closeToolbox.bind(this);
    this.state = { customers: [], showsCreateForm: false, editCustomer: undefined };
  }

  componentDidMount() {
    this.fetchCustomers();
  }

  fetchCustomers() {
    Api.get("/customers").then((customers) => this.setState({ customers: customers }) );
  }

  didUpdateCustomer() {
    this.fetchCustomers();
    this.closeToolbox();
  }

  closeToolbox() {
    this.setState({ showsCreateForm: false, editCustomer: undefined });
  }

  removeCustomer(customer) {
    if (confirm("Möchtest du diesen Kunden permanent entfernen?") == true) {
      Api.delete(`/customers/${customer.id}`).then(this.fetchCustomers);
    }
  }

  impactFactorInfo() {
    return (
      <MoreMenu icon={<HelpOutlineOutlinedIcon fontSize="small" />} sx={{top: "-2px"}}>
        { Customers.IMPACT_FACTORS.map(factor => 
          <ListItem key={factor.label} sx={{alignItems: 'baseline'}}>
            <ListItemIcon sx={{minWidth: '20px'}}><span className={`circle-${factor.color}`} /></ListItemIcon>
            <ListItemText>
              <p><small><strong>{factor.label}</strong></small></p>
              <p><small>{factor.info}</small></p>
            </ListItemText>
          </ListItem>
        )}
      </MoreMenu>
    )
  }
  
  render() {
    return (
      <Container maxWidth="lg">

        <Stack direction="row" sx={{mb: '2rem', placeContent: 'space-between'}}>
          <h2>Kunden Insights</h2>
          <Button variant="contained" color="primary" onClick={() => {this.setState({showsCreateForm: true})}} startIcon={<AddIcon />}>Kunde hinzufügen</Button>
          { this.state.showsCreateForm && <Navigate to="/customers/new" /> }
          { this.state.editCustomer && <Navigate to={`/customers/${this.state.editCustomer.id}/edit`} /> }
          <CustomerToolbox onCancel={this.closeToolbox} completion={this.didUpdateCustomer} />
        </Stack>

        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><h3><strong>Name / UID</strong></h3></TableCell>
                  <TableCell><h3><strong>Adresse</strong></h3></TableCell>
                  <TableCell><h3><strong>Einflussfaktor</strong> {this.impactFactorInfo()}</h3></TableCell>
                  <TableCell align="right"><h3><strong>Aktionen</strong></h3></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {this.state.customers.map(customer => 
                <CustomerInfo
                  key={customer.id}
                  customer={customer}
                  onEdit={() => {this.setState({ editCustomer: customer })}}
                  onRemove={() => {this.removeCustomer(customer)}}
                  impactFactors={Customers.IMPACT_FACTORS}
                />
              )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    );
  }
};

export default Customers;