import React from 'react';
import { Button, FormGroup } from '@mui/material';

class Paginator extends React.Component {

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.state = { page: 1 };
  }

  prev() {
    this.setState((prevState) => ({...prevState, page: prevState.page - 1 }));
  }

  next() {
    this.setState((prevState) => ({...prevState, page: prevState.page + 1 }));
  }

  buttons() {
    return (
      <FormGroup row={true} sx={{mt: "1rem !important;"}}>
        { this.state.page == 1 ?
          <Button variant="contained" color="secondary" onClick={this.props.onCancel}>Abbrechen</Button> : 
          <Button variant="contained" color="secondary" onClick={this.prev}>Zurück</Button>
        }
        { this.state.page == this.props.pages.length ?
          <Button variant="contained" color="primary" type="submit">{this.props.caption}</Button> :
          <Button variant="contained" color="primary" onClick={this.next}>Weiter</Button>
        }
      </FormGroup>
    )
  }

  render() {
    return this.props.pages[this.state.page - 1]();
  }
};

export default Paginator;