import React, { useState } from 'react';
import { Stepper, Step, StepLabel, StepContent, Button, FormGroup, TextField, Alert, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Api from '../../network/Api';
import { CountrySelector, TaxRateSelector, VatIdField } from '../Shared/FormItems';

const Onboarding = (props) => {
  
  const [isLoading, shouldLoad] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [params, setParams] = useState({});

  const steps = [
    { label: 'Dein Unternehmen', view: (<>
      <FormGroup sx={{mt: '1rem'}}>
        <CountrySelector 
          name="country_code" 
          required
          label="Land"
          defaultValue={params.country_code}
          onChange={(e) => { setParams({...params, country_code: e.target.value}) }}
          filter={['AT']}
        />
      </FormGroup>
      <FormGroup><TextField name="name" label="Firmenname" defaultValue={params.name} required variant="filled" onChange={(e) => { setParams({...params, name: e.target.value}) }} /></FormGroup>
    </>) },
    { label: 'Einstellungen zu Steuern', view: (<>
      <FormGroup><Alert severity="info">Du kannst diesen Schritt auch überspringen und später in den Einstellungen fertigstellen.</Alert></FormGroup>
      <FormGroup><TextField name="tax_id" label="Steuernummer" defaultValue={params.tax_id} variant="filled" onChange={(e) => { setParams({...params, tax_id: e.target.value}) }} /></FormGroup>
      <FormGroup><VatIdField defaultValue={params.vat_id} onChange={(e) => { setParams({...params, vat_id: e.target.value}) }} /></FormGroup>
      <FormGroup>
        <TaxRateSelector name="default_tax_rate_id" defaultValue={params.default_tax_rate_id} onChange={(e) => { setParams({...params, default_tax_rate_id: e.target.value}) }} label="Standard Steuersatz" taxRates={props.taxRates.default} />
      </FormGroup>
    </>) },
    { label: 'Fertigstellen', view: (<>
      <FormGroup><Alert severity="success">Du bist jetzt startklar!</Alert></FormGroup>
    </>) }
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const finish = () => {
    shouldLoad(true);
    Api.post('/company', params).then((result) => result.url ? window.location.replace(result.url) : window.location.reload() );
  }

  return (<>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <form onSubmit={(e) => { e.preventDefault(); index === steps.length - 1 ? finish() : handleNext(); }}>
                {step.view}
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {index === steps.length - 1 ? 'Los starten' : 'Weiter'}
                </LoadingButton>
                { index >= 1 && <Button
                  disabled={isLoading}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Zurück
                </Button> }
              </form>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default Onboarding;