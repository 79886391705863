
import React from 'react';
import { Button, Stepper, Step, StepLabel, StepContent, Stack, FormGroup, TextField, MenuItem, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RevenueProperties from './RevenueProperties';
import ExpenseProperties from './ExpenseProperties';
import TaxItemsForm from './TaxItemsForm';
import Api from '../../../network/Api';
import DocumentAnalyzer from './DocumentAnalyzer';
import consumer from "../../../channels/consumer";

class Form extends React.Component {

  constructor(props) {
    super(props);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.changeItem = this.changeItem.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.state = {
      subscription: null,
      item: this.props.item ?? {},
      activeStep: this.props.item === undefined ? 0 : 1,
      isAnalyzing: false,
      isSaving: false
    };
  };

  componentDidMount() {
    const subscription = consumer.subscriptions.create('CompanyChannel', {
      received: (data) => {
        switch (data.type) {
          case 'analyzed_document':
            this.setState(prevState => ({...prevState, item: {...prevState.item, ...data.content}, isAnalyzing: false }));
          default: break;
        }
      }
    });
    this.setState({ subscription: subscription });
  }

  componentWillUnmount() {
    consumer.subscriptions.remove(this.state.subscription);
  }

  prev() {
    this.setState((prevState) => ({...prevState, activeStep: prevState.activeStep - 1}))
  }

  next() {
    this.setState((prevState) => ({...prevState, activeStep: prevState.activeStep + 1}))
  }

  changeItem(params) {
    this.setState(prevState => ({...prevState, item: {...prevState.item, ...params}}))
  }

  saveItem() {
    this.setState({ isSaving: true });
    var path = `/${this.state.item.type == 'revenue' ? 'revenues' : 'expenses'}${this.props.item ? `/${this.props.item.id}` : ''}`;
    (this.props.item ? Api.put(path, this.state.item) : Api.post(path, this.state.item)).then((result) => this.props.completion());
  }

  render() {
    return (<>
      { this.props.item == undefined && <>{ this.props.user.is_premium ? 
        <DocumentAnalyzer
          isAnalyzing={this.state.isAnalyzing}
          item={this.state.item}
          taxRates={this.props.taxRates}
          onStart={() => this.setState({ isAnalyzing: true })}
          onResult={result => {
            this.changeItem(result);
            this.setState({isAnalyzing: false});
        }} /> : 
        <Paper elevation={0} className='bg-info' sx={{padding: '1rem'}}>
          <p className='text-emphasize'><strong>Du willst deine Rechnungen einfach hochladen und von der Künstlichen Intelligenz abtippen lassen?</strong></p>
          <p><small>Mit ProfitDesk Premium ist das möglich.</small></p>
          <Button sx={{mt: '1rem'}} component={NavLink} to="/products" size="small" variant="contained" color="info">Premium werden</Button>
        </Paper>
      }</> }

      <Stepper activeStep={this.state.activeStep} orientation="vertical" sx={{mt: '1rem'}}>
        <Step key={0}>
          <StepLabel>Zahlungsart</StepLabel>
          <StepContent>
            <RadioGroup
              onChange={(event, type) => {
                this.changeItem({ type: type });
              }}
            >
              <FormControlLabel value="revenue" control={<Radio disabled={this.props.item != undefined || this.state.isAnalyzing} checked={this.state.item.type == 'revenue'} />} label="Einnahme" />
              <FormControlLabel value="expense" control={<Radio disabled={this.props.item != undefined || this.state.isAnalyzing} checked={this.state.item.type == 'expense'} />} label="Ausgabe" />
            </RadioGroup>
            
            <Stack direction="row" spacing={1}>
              <Button
                  disabled={this.state.item.type === undefined}
                  variant="contained"
                  onClick={this.next}
                >
                Weiter
              </Button>
            </Stack> 
          </StepContent>
        </Step>

        <Step key={1}>
          <StepLabel>Zuordnung</StepLabel>
          <StepContent>
            <Stack spacing={2} sx={{alignItems: 'normal !important'}}>
              { this.state.item.type !== undefined && (this.state.item.type == 'revenue' ? 
                <RevenueProperties
                  item={this.state.item}
                  isAnalyzing={this.state.isAnalyzing} 
                  onNext={this.next} 
                  onBack={this.prev} 
                  onChange={this.changeItem} /> : 
                <ExpenseProperties
                  item={this.state.item}
                  isAnalyzing={this.state.isAnalyzing} 
                  onNext={this.next}
                  onBack={this.prev}
                  onChange={this.changeItem} />) }
            </Stack>
          </StepContent>
        </Step>

        <Step key={2}>
          <StepLabel>Steuer Positionen</StepLabel>
          <StepContent>
            <TaxItemsForm
              item={{...this.state.item, vat_deductible: this.state.item.vat_deductible ?? true, tax_items: this.state.item.tax_items ?? [] }}
              taxRates={this.props.taxRates}
              company={this.props.company}
              onChange={this.changeItem}
            />

            <Stack direction="row" spacing={1} sx={{justifyContent: 'center !important'}}>
              <LoadingButton
                variant="contained"
                onClick={this.saveItem}
                loading={this.state.isSaving}
              >
                Fertigstellen
              </LoadingButton>
              <Button
                onClick={this.prev}
                disabled={this.state.isSaving}
              >
                Zurück
              </Button>
            </Stack>
          </StepContent>
        </Step>
      </Stepper>
    </>)
  };
};

export default Form;