export default class Api {

  static get(path) {
    return this.request(path, 'get', null);
  }

  static post(path, params) {
    return this.request(path, 'post', params);
  }

  static put(path, params) {
    return this.request(path, 'put', params);
  }

  static delete(path) {
    return this.request(path, 'delete', null);
  }

  static upload(path, file) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    const formData  = new FormData();
      formData.append('file', file);
      const options = { method: 'post', 
                        headers: { 'Accept': 'application/json', 'X-CSRF-Token': csrfToken },
                        body: formData }
    
      return fetch(path, options)
        .then((response) => {
          return response.json()
        })
        .catch(error => console.log('API error', error))
  }

  static request(path, method, params) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    const options = { method: method, 
                      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'X-CSRF-Token': csrfToken },
                      body: params && JSON.stringify(params) };
    
    return fetch(path, options)
      .then((response) => {
        return response.json()
      })
      .catch(error => console.log('API error', error))
  }
}