import React from 'react';
import { Stack } from '@mui/material';

const Privacy = (props) => {
  return (<Stack spacing={2}>
    <h2>Datenschutzerklärung für die Buchhaltungs-Software "ProfitDesk":</h2>

<p>Diese Datenschutzerklärung erläutert, wie wir Ihre persönlichen Daten sammeln, verwenden, offenlegen und schützen, wenn Sie die Buchhaltungs-Software "ProfitDesk" nutzen. Diese Erklärung entspricht den Datenschutzbestimmungen des österreichischen Rechts und ist DSGVO-konform.</p>

<h3>1. Gesammelte Daten:</h3>

<p>a. Bei der Nutzung von "ProfitDesk" erfassen wir personenbezogene Daten, die Sie bei der Registrierung und Nutzung der Software angeben. Diese Daten können Ihren Namen, Ihre Kontaktdaten und Unternehmensinformationen umfassen.</p>

<p>b. Wir erfassen auch unternehmerische Daten, die Sie in die Software eingeben, um Buchhaltungstätigkeiten zu unterstützen. Diese Daten sind für die Funktion der Software notwendig.</p>

<h3>2. Verwendung von Daten:</h3>

<p>a. Ihre personenbezogenen Daten werden verwendet, um Ihnen den Zugang zur Software und die Nutzung der Dienste zu ermöglichen.</p>

<p>b. Unternehmerische Daten, die in die Software eingegeben werden, dienen der Erstellung von Buchhaltungsdokumenten und Berichten innerhalb der Software.</p>

<h3>3. Datenweitergabe:</h3>

<p>a. Wir geben Ihre persönlichen Daten nicht an Dritte weiter, es sei denn, dies ist zur Erfüllung unserer Dienstleistungen oder aufgrund gesetzlicher Verpflichtungen erforderlich.</p>

<p>b. Wir können Dritte beauftragen, technische Dienstleistungen zur Unterstützung der Software bereitzustellen, wobei diese Dritten Zugriff auf Ihre Daten haben können. In diesen Fällen sind angemessene Datenschutzmaßnahmen getroffen, um die Sicherheit Ihrer Daten zu gewährleisten.</p>

<h3>4. Datensicherheit:</h3>

<p>a. Wir ergreifen angemessene technische und organisatorische Maßnahmen, um Ihre Daten vor unbefugtem Zugriff und Verlust zu schützen.</p>

<p>b. Bitte beachten Sie, dass keine Datenübertragung über das Internet zu 100 % sicher ist.</p>

<h3>5. Google Analytics:</h3>

<p>a. Wir verwenden Google Analytics, einen Webanalysedienst von Google Inc. („Google“), um Informationen über die Nutzung unserer Webapplikation zu sammeln. Google Analytics verwendet Cookies und ähnliche Technologien, um Daten über Ihre Interaktion mit unserer Anwendung zu sammeln. Diese Informationen dienen dazu, die Nutzung unserer Anwendung zu analysieren, Berichte über Aktivitäten zu erstellen und uns bei der Verbesserung unserer Dienstleistungen zu unterstützen.

Google Analytics kann folgende Informationen erfassen:

Ihre IP-Adresse
Art des Browsers und Gerätetyp
Betriebssystem
Herkunftsland
Seitenaufrufe und Navigation auf unserer Website
Verweildauer auf unserer Website
Interaktionen mit unserer Anwendung
Die von Google Analytics erzeugten Informationen über Ihre Nutzung unserer Webapplikation werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Google beachtet die Datenschutzbestimmungen des Privacy Shield-Abkommens und ist beim Privacy Shield-Programm des US-Handelsministeriums registriert.

Wir verwenden Google Analytics, um die Nutzung unserer Webapplikation zu analysieren und um unsere Dienstleistungen zu optimieren. Die Verwendung von Google Analytics erfolgt auf der Grundlage unseres berechtigten Interesses an der Analyse und Verbesserung unserer Dienstleistungen.</p>

<p>b. Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie die Nutzung von Cookies auf unserer Webseite ablehnen. Bei bereits erfolger Zustimmung müssen Sie zuvor die Cookies in Ihrem Webbrowser löschen.</p>

<p>c. Weitere Informationen zur Verwendung von Google Analytics finden Sie in der Google Datenschutzrichtlinie.</p>

<h3>6. Facebook Ads Tracking:</h3>

<p>a. Wir verwenden das Facebook Ads Tracking-Tool, das von Facebook, Inc. bereitgestellt wird, um die Effektivität unserer Werbekampagnen auf Facebook und seinen verbundenen Diensten zu analysieren. Facebook Ads Tracking verwendet Cookies und ähnliche Technologien, um Informationen über Ihre Interaktion mit unseren Anzeigen auf Facebook zu sammeln. Diese Informationen dienen dazu, die Leistung unserer Werbekampagnen zu bewerten, die Zielgruppen besser zu verstehen und die Anzeigen zu optimieren.

Facebook Ads Tracking kann folgende Informationen erfassen:

Informationen über Ihre Interaktion mit unseren Anzeigen auf Facebook, wie Klicks und Impressions.
Informationen über Ihre Navigation auf unserer Webseite nach dem Klicken auf eine unserer Anzeigen.
Demografische Informationen, wie Alter, Geschlecht und Standort, wenn Sie diese Daten in Ihrem Facebook-Profil angegeben haben.
Die von Facebook Ads Tracking erzeugten Informationen über Ihre Interaktion mit unseren Anzeigen werden in der Regel an einen Server von Facebook in den USA übertragen und dort gespeichert. Facebook beachtet die Datenschutzbestimmungen des Privacy Shield-Abkommens und ist beim Privacy Shield-Programm des US-Handelsministeriums registriert.

Wir verwenden Facebook Ads Tracking, um die Wirksamkeit unserer Werbekampagnen zu analysieren und unsere Dienstleistungen zu verbessern. Die Verwendung von Facebook Ads Tracking erfolgt auf der Grundlage unseres berechtigten Interesses an der Analyse und Verbesserung unserer Werbemaßnahmen.
</p>
<p>b. Sie können die Verwendung von Facebook Ads Targeting verhindern, indem Sie die Nutzung von Cookies auf unserer Webseite ablehnen. Bei bereits erfolger Zustimmung müssen Sie zuvor die Cookies in Ihrem Webbrowser löschen.</p>

<p>c. Weitere Informationen zur Verwendung von Facebook Ads Tracking finden Sie in der Facebook-Datenschutzrichtlinie.</p>

<h3>7. Linkedin Ads Tracking:</h3>

<p>a. Wir verwenden das LinkedIn Ads Tracking-Tool, das von LinkedIn Corporation bereitgestellt wird, um die Effektivität unserer Werbekampagnen auf LinkedIn und seinen verbundenen Diensten zu analysieren. LinkedIn Ads Tracking verwendet Cookies und ähnliche Technologien, um Informationen über Ihre Interaktion mit unseren Anzeigen auf LinkedIn zu sammeln. Diese Informationen dienen dazu, die Leistung unserer Werbekampagnen zu bewerten, die Zielgruppen besser zu verstehen und die Anzeigen zu optimieren.

LinkedIn Ads Tracking kann folgende Informationen erfassen:

Informationen über Ihre Interaktion mit unseren Anzeigen auf LinkedIn, wie Klicks und Impressions.
Informationen über Ihre Navigation auf unserer Webseite nach dem Klicken auf eine unserer Anzeigen.
Demografische Informationen, wie Beruf und Standort, wenn Sie diese Daten in Ihrem LinkedIn-Profil angegeben haben.
Die von LinkedIn Ads Tracking erzeugten Informationen über Ihre Interaktion mit unseren Anzeigen werden in der Regel an einen Server von LinkedIn in den USA übertragen und dort gespeichert. LinkedIn beachtet die Datenschutzbestimmungen des Privacy Shield-Abkommens und ist beim Privacy Shield-Programm des US-Handelsministeriums registriert.

Wir verwenden LinkedIn Ads Tracking, um die Wirksamkeit unserer Werbekampagnen zu analysieren und unsere Dienstleistungen zu verbessern. Die Verwendung von LinkedIn Ads Tracking erfolgt auf der Grundlage unseres berechtigten Interesses an der Analyse und Verbesserung unserer Werbemaßnahmen.</p>
<p>b. Sie können die Verwendung von Linkedin Ads Targeting verhindern, indem Sie die Nutzung von Cookies auf unserer Webseite ablehnen. Bei bereits erfolger Zustimmung müssen Sie zuvor die Cookies in Ihrem Webbrowser löschen.</p>

<p>c. Weitere Informationen zur Verwendung von Linkedin Ads Tracking finden Sie in der Linkedin-Datenschutzrichtlinie.</p>

<h3>8. Ihre Rechte:</h3>

<p>a. Sie haben das Recht auf Zugang, Berichtigung und Löschung Ihrer personenbezogenen Daten.</p>

<p>b. Sie können auch Widerspruch gegen die Verarbeitung Ihrer Daten einlegen.</p>

<h3>9. Kontakt:</h3>

<p>a. Bei Fragen oder Anliegen in Bezug auf den Datenschutz können Sie uns unter office@profitdesk.at erreichen.</p>

<p>Diese Datenschutzerklärung kann von Zeit zu Zeit aktualisiert werden, um Änderungen in unseren Datenschutzpraktiken zu reflektieren. Bitte überprüfen Sie regelmäßig auf Aktualisierungen.</p>
  </Stack>)
}

export default Privacy;