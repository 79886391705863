import React from 'react';
import FormUtils from "../../utils/FormUtils";
import { Button, FormGroup, TextField } from '@mui/material';
import { CountrySelector, VatIdField } from '../Shared/FormItems';

const CustomerForm = (props) => {
  return (
    <form method={props.method} action={props.path} onSubmit={(e) => { FormUtils.onFormSubmit(e, props.completion) }}>
      <FormGroup>
        <TextField name="name" required label="Firmenname" defaultValue={props.name} variant="filled" />
      </FormGroup>

      <FormGroup sx={{mt: "1rem !important;"}}>
        <TextField name="address" label="Adresse" defaultValue={props.address} variant="filled" />
      </FormGroup>

      <FormGroup row={true}>
        <TextField name="zip" label="PLZ" defaultValue={props.zip} variant="filled" />
        <TextField name="city" label="Ort" defaultValue={props.city} variant="filled" />
      </FormGroup>

      <FormGroup>
        <CountrySelector name="country_code" label="Land" defaultValue={props.country_code} />
      </FormGroup>

      <FormGroup sx={{mt: "1rem !important;"}}>
        <VatIdField defaultValue={props.vat_id} />
      </FormGroup>

      <FormGroup row={true} sx={{mt: "2rem !important;"}} align="center">
        <Button variant="outlined" color="secondary" onClick={props.onCancel}>Abbrechen</Button>
        <Button variant="contained" color="primary" type="submit">{props.caption}</Button>
      </FormGroup>
    </form>
  );
};

export default CustomerForm;