import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FormUtils from "../../utils/FormUtils";
import { FormGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Api from '../../network/Api';

const VerificationForm = (props) => {

  const { userId } = useParams();
  const [otp, setOtp] = useState('');
  const [isLoading, shouldLoad] = useState(false);

  return (
    <form method="put" action={`/users/${userId}/verification`} onSubmit={(e) => { FormUtils.onFormSubmit(e); shouldLoad(true); }}>
      <FormGroup>
        <h2>Email Verifizierung</h2>
        <p>Bitte überprüfe deine Mailbox. Du solltest eine Email mit einem Verifizierungs Code erhalten haben.</p>
      </FormGroup>

      <FormGroup>
        <MuiOtpInput length={6} value={otp} onChange={(newValue) => setOtp(newValue)} />
        <input type="hidden" name="code" value={otp} />
      </FormGroup>

      <FormGroup>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>Verifizieren</LoadingButton>
      </FormGroup>

      <FormGroup>
        <p className='text-center'>Keinen Code erhalten? <a href="#" onClick={() => Api.post(`/users/${userId}/verification`).then(result => window.location.replace(result.url))}>Erneut senden</a></p>
      </FormGroup>
    </form>
  );
};

export default VerificationForm;