import React from 'react';
import { Paper, Stack, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from "react-router-dom";

const OnboardingSteps = (props) => {

  const done = props.steps.filter(step => step.done).length;

  return (
    <Paper elevation={0} className='bg-success' sx={{mb: '1rem', padding: '1rem'}}>
      <Stack direction="row" sx={{placeContent: 'space-between'}}>
        <h3 style={{marginLeft: '1rem'}}>Du hast {done}/{props.steps.length} Schritten erledigt, um das Maximum aus ProfitDesk herauszuholen:</h3>
        <IconButton
          color="inherit"
          size="small"
          onClick={props.onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Stack>
      
      <LinearProgress 
        color="success"
        variant="determinate" 
        value={100 / props.steps.length * done}
        sx={{mt: '1rem', mb: 0, mx: '1rem'}} />

      <List>
      { props.steps.map(step => {
        return <ListItem key={step.todo} sx={{pb: 0}}>
          <ListItemIcon>{ step.done ? <CheckBoxIcon className='text-success' /> : <CheckBoxOutlineBlankIcon /> }</ListItemIcon>
          <ListItemText>{ step.done ? <span style={{opacity: 0.6}}>{step.todo}</span> : <NavLink to={step.link}>{step.todo}</NavLink> }</ListItemText>
        </ListItem>
      }) }
      </List>
    </Paper>
  );
}

export default OnboardingSteps;