import React, { useState } from 'react';
import FormUtils from "../../utils/FormUtils";
import { Button, FormGroup, TextField } from '@mui/material';
import { CountrySelector } from '../Shared/FormItems';

const CompanyForm = (props) => {
  const [didChange, setDidChange] = useState(false);

  const onChange = () => {
    setDidChange(true);
  }

  return (
    <form method="put" action="/company" onChange={onChange} onSubmit={(e) => { FormUtils.onFormSubmit(e, props.completion); setDidChange(false); }}>
      <FormGroup>
        <TextField name="name" required label="Firmenname" defaultValue={props.name} variant="filled" />
      </FormGroup>

      <FormGroup>
        <TextField name="address" required label="Adresse" defaultValue={props.address} variant="filled" />
      </FormGroup>

      <FormGroup row={true}>
        <TextField name="zip" required label="PLZ" defaultValue={props.zip} variant="filled" />
        <TextField name="city" required label="Ort" defaultValue={props.city} variant="filled" />
      </FormGroup>

      <FormGroup>
        <CountrySelector 
          name="country_code" 
          required 
          label="Land"
          defaultValue={props.country_code} 
          onChange={onChange}
          filter={['AT']}
        />
      </FormGroup>

      <FormGroup row={true}>
        <Button sx={{mr: 'auto'}} variant="contained" color="primary" type="submit" disabled={!didChange}>Speichern</Button>
      </FormGroup>
    </form>
  );
};

export default CompanyForm;