import React from 'react';
import { TextField, MenuItem, Button, FormControlLabel, Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';

export const RangeSwitch = (props) => {
  return (
    <>
      <Button size="small" variant="contained" color={props.defaultValue == 'month' ? props.color : 'secondary'}>Monat</Button>
      <Button size="small" className="text-white">Jahr</Button>
    </>
  )
};

export const YearSelect = (props) => {

  const [cookies, setCookie] = useCookies(['year']);

  let currentYear = dayjs().year();
  var startYear = dayjs().subtract(7, 'year').year();
  var years = [];
  while(startYear <= currentYear) {
    years.push(startYear++);
  }

  return (
    <TextField
      name="year" value={props.value ?? dayjs().year()}
      variant="standard"
      select
      onChange={(e) => {
        setCookie(e.target.name, e.target.value);
        props.onChange(e.target.value);
      }}
    >
      {years.reverse().map((year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </TextField>
  )
};

export const QuarterFilter = (props) => {

  const [cookies, setCookie] = useCookies(['quarters']);

  const quarters = () => {
    return cookies.quarters ?? ['1','2','3','4'];
  }

  const isChecked = (q) => {
    return quarters().includes(q);
  }

  const onChange = (e) => {
    var ary = quarters();
    let q = e.target.value;
    let checked = e.target.checked;
    let index = ary.indexOf(q);
    if (checked && index === -1) {
      ary.push(q);
    } else if (!checked && index >= 0) {
      ary.splice(index, 1);
    }
    setCookie('quarters', ary);
    props.onChange(ary);
  }

  return (<>
      <FormControlLabel control={<Checkbox value="1" checked={isChecked('1')} onChange={onChange} />} label="Q1" />
      <FormControlLabel control={<Checkbox value="2" checked={isChecked('2')} onChange={onChange} />} label="Q2" />
      <FormControlLabel control={<Checkbox value="3" checked={isChecked('3')} onChange={onChange} />} label="Q3" />
      <FormControlLabel control={<Checkbox value="4" checked={isChecked('4')} onChange={onChange} />} label="Q4" />
    </>
  )
};