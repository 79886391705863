import React from 'react';
import AT from './AT/Main';

const Landingpage = (props) => {
  switch (props.countryCode) {
    case 'AT':
      return <AT {...props} />
    default:
      return <AT {...props} />
  }
}

export default Landingpage;