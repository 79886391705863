import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Stack, IconButton } from '@mui/material';
import Toolbox from '../Shared/Toolbox';
import CloseIcon from '@mui/icons-material/Close';
import Form from './Forms/Form';
import Api from '../../network/Api';

const CashflowToolbox = (props) => {

  const { revenueId, expenseId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState();

  useEffect(() => {
    if (revenueId === undefined && expenseId === undefined) { 
      setItem(undefined);
      return
    }
    if (revenueId) {
      Api.get(`/revenues/${revenueId}`).then(setItem);
    } else if (expenseId) {
      Api.get(`/expenses/${expenseId}`).then(setItem);
    }
  }, [revenueId, expenseId]);

  const cancel = () => {
    props.onCancel();
    navigate('/cashflow');
  }

  const completion = () => {
    props.completion();
    navigate('/cashflow');
  }

  return (
    <>
      <Toolbox isShown={pathname == '/cashflow/new' || item !== undefined}>
        <Stack direction="row" sx={{mb: '2rem', placeContent: 'space-between'}}>
          <div>
            <h2>{ item ? "Zahlungsfluss bearbeiten" : "Zahlungsfluss hinzufügen" }</h2>
            { !item && <p>Eine neue Einnahme oder Ausgabe im Board anlegen</p>}
          </div>
          <IconButton size="small" onClick={cancel}><CloseIcon /></IconButton>
        </Stack>
        <Form
          user={props.user} 
          company={props.company} 
          taxRates={props.taxRates} 
          item={item} 
          completion={completion} />
      </Toolbox>
    </>
  );
};

export default CashflowToolbox;