import React, { useState } from "react";
import Dropzone from 'react-dropzone'
import Api from '../../../network/Api';
import { CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const DocumentAnalyzer = (props) => {

  const [file, setFile] = useState();

  const upload = (file) => {
    setFile(file);
    props.onStart();
    Api.upload('/documents', file);
  }

  return (
    <Dropzone 
      accept={{
        'application/pdf': ['.pdf']
      }}
      multiple={false} 
      maxFiles={1}
      disabled={props.isAnalyzing}
      onDrop={acceptedFiles => upload(acceptedFiles[0])}>
      {({getRootProps, getInputProps}) => (
        <div className='file-dropzone' {...getRootProps()}>
          <input {...getInputProps()} />
          { props.isAnalyzing ? <>
            <CircularProgress color="secondary" sx={{mb: '0.5rem'}} />
            <p>Analysiere <strong>{file.path}</strong> ...</p>
          </> : file ? <>
            <CheckOutlinedIcon fontSize="large" className="text-secondary" />
            <p><strong>{file.path}</strong> wurde im unteren Formular übernommen.</p>
          </> : <>
            <p><strong>Rechnung / Beleg mittels KI einpflegen</strong></p>
            <CloudUploadIcon sx={{mt: '0.5rem'}} fontSize="large" className="text-secondary" />
            <p><small>Hier klicken oder Datei hineinziehen</small></p>
            <p><small><strong>Achtung:</strong> Die Datei wird danach wieder verworfen.</small></p>
          </> }
        </div>
      )}
    </Dropzone>
  )
}

export default DocumentAnalyzer;