import React, { useState } from 'react';
import { FormGroup, TextField, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink } from "react-router-dom";
import FormUtils from "../../utils/FormUtils";
import GoogleSignIn from './GoogleSignIn';
import OrDivider from '../Shared/OrDivider';
import { PasswordForm } from '../Shared/FormItems';

const RegistrationForm = (props) => {

  const [isLoading, shouldLoad] = useState(false);

  return (
    <form method="post" action="/users" onSubmit={(e) => { FormUtils.onFormSubmit(e); shouldLoad(true); }}>

      <FormGroup sx={{mb: "1rem !important;"}}>
        <h2>Neu-Registrierung</h2>
      </FormGroup>

      <FormGroup>
        <GoogleSignIn />
      </FormGroup>

      <FormGroup>
        <OrDivider />
      </FormGroup>

      <FormGroup row={true}>
        <TextField name="first_name" required label="Vorname" variant="filled" />
        <TextField name="last_name" required label="Nachname" variant="filled" />
      </FormGroup>

      <FormGroup>
        <TextField name="email" type="email" required label="Email" variant="filled" />
      </FormGroup>

      <PasswordForm />

      <FormGroup>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>Mit Email Adresse registrieren</LoadingButton>
      </FormGroup>

      <Divider />

      <p className='text-center'>Bereits registriert? <NavLink to="/login">Zur Anmeldung</NavLink></p>
    </form>
  );
};

export default RegistrationForm;