import React, { useState } from 'react';
import Topbar from '../Shared/Topbar';
import Sidebar from '../Shared/Sidebar';
import { Drawer, IconButton, Toolbar, Snackbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/MenuOpen';
import { useCookies } from 'react-cookie';
import InfoLayout from './InfoLayout';
import ConsentScripts from '../../utils/ConsentScripts';

const Layout = (props) => {

  const [cookies, setCookie] = useCookies(['mini_sidebar']);
  const [miniSidebar, showsMiniSidebar] = useState(cookies.mini_sidebar == "true");
  const [mobileSidebar, showsMobileSidebar] = useState(false);
  const [flash, setFlash] = useState(props.flash);

  const toggleSidebar = (minify) => {
    if (minify === undefined) {
      setCookie('mini_sidebar', !miniSidebar);
    }
    showsMiniSidebar(minify ?? !miniSidebar);
  }

  const toggleMobileSidebar = () => {
    showsMobileSidebar(!mobileSidebar);
  }

  return (<>

    <ConsentScripts {...props} />

    { flash?.filter((flash) => flash.severity == 'event')?.forEach((event) => {
      gtag('event', event.message.name);
      fbq('track', event.message.name, {}, { eventID: event.message.id });
    })}

    { flash?.filter((flash) => flash.severity != 'event').map((flash) => {return <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={true}
      onClose={() => setFlash([])}
      message={flash.message}
      key={flash.message}
      autoHideDuration={10000}
    />})}

    { props.info ? <InfoLayout {...props} /> :
    <div id="wrapper">

      <Drawer id="sidebar" className={miniSidebar ? "sidebar-mini" : ""} variant="permanent" anchor="left" >
        <Toolbar>
          <IconButton onClick={() => toggleSidebar()} sx={{ml: 'auto'}}>
            <MenuIcon className={"text-secondary"} />
          </IconButton>
        </Toolbar>
        <Sidebar onToggle={toggleSidebar} />
      </Drawer>

      <Drawer id="sidebar-mobile" anchor="left" 
        open={mobileSidebar}
        onClose={toggleMobileSidebar}
      >
        <Toolbar>
          <IconButton sx={{ml: 'auto'}} onClick={toggleMobileSidebar}>
            <MenuIcon className={"text-secondary"} />
          </IconButton>
        </Toolbar>
        <Sidebar onToggle={() => {}} />
      </Drawer>

      <div id="main">
        <Topbar 
          toggleMobileSidebar={toggleMobileSidebar}
          user={props.user} />
        <div id="content">{props.children}</div>
      </div>

    </div> }
  </>)
};

export default Layout;