import React from 'react';
import { Divider, Button, FormGroup, TextField, InputAdornment, Paper, Stack, IconButton, Stepper, Step, StepLabel, StepContent, Skeleton, Tooltip, Alert } from '@mui/material';
import { NavLink } from "react-router-dom";
import Api from '../../network/Api';
import dayjs from 'dayjs';
import i18n from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import OrDivider from '../Shared/OrDivider';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

class Report extends React.Component {

  constructor(props) {
    super(props);
    this.state = { report: undefined, activeStep: 0 };
    this.purchase = this.purchase.bind(this);
  }

  componentDidMount() {
    Api.get(`/reports/${this.props.deadline}`).then((result) => this.setState({ report: result }));
  }

  purchase() {
    Api.get(`/reports/${this.props.deadline}/payment/new`).then((result) => window.location.replace(result.url));
  }

  render() {
    return (
      <>
        { this.state.report ? <>
        <Stack direction="row" sx={{placeContent: 'space-between'}}>
          <div>
            <h2>Umsatzsteuervoranmeldung</h2>
            <p>Zeitraum: {dayjs.unix(this.state.report?.period.from).utc().format('MMMM YYYY')} - {dayjs.unix(this.state.report?.period.to).utc().format('MMMM YYYY')}</p>
            <p>Fälligkeit: {dayjs.unix(this.props.deadline).utc().format('DD. MMMM YYYY')}</p>
          </div>
          <IconButton size="small" onClick={this.props.completion}><CloseIcon /></IconButton>
        </Stack>
        
        <Divider />

        <FormGroup sx={{paddingBottom: '0 !important'}}>
          <h3 className='text-center'>Erwartetes Ergebnis: <span className={this.state.report?.result >= 0 ? 'text-success' : 'text-error'}>{this.state.report?.result > 0 ? "+" : ""}{i18n.t('currency_label', { val: this.state.report?.result, currency: this.props.company.default_currency})}</span></h3>
        </FormGroup>

        <Divider />   

        { this.state.report?.form ? <>

          <Alert severity="warning" sx={{mb: '1rem'}}><small>Bitte überprüfe selbstständig, ob sämtliche Positionen korrekt übernommen wurden oder überlasse das ggfs. einem Experten. Es wird keine Haftung über die Richtigkeit und Vollständigkeit übernommen.</small></Alert>

          <Stepper activeStep={this.state.activeStep} orientation="vertical" sx={{mb: '1rem'}}>
            {this.state.report.form.pages?.map((form, index) => (
              <Step key={form.category}>
                <StepLabel>{form.category}</StepLabel>
                <StepContent>
                  { form.fields.length > 0 ? form.fields.map((field) => {
                      return(field.value !== null && <FormGroup key={field.id}>
                        <TextField
                          readOnly
                          variant="filled"
                          value={i18n.t('intlNumber', { val: field.value, useGrouping: false })}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" sx={{mr: '1rem'}}>Feld {field.id}:</InputAdornment>
                          }} />
                      </FormGroup>)
                    }) : <FormGroup><p className='text-secondary'>Nichts einzutragen</p></FormGroup>}
                  <Button
                    variant="contained"
                    onClick={() => this.setState(prevState => ({...prevState, activeStep: prevState.activeStep + 1 })) }
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Weiter
                  </Button>
                  { index > 0 && <Button
                    onClick={() => this.setState(prevState => ({...prevState, activeStep: prevState.activeStep - 1 }))}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Zurück
                  </Button> }
                </StepContent>
              </Step>
            ))}

            { this.state.report?.file && <Step key="file">
              <StepLabel>Fertigstellen</StepLabel>
              <StepContent>
                <Paper elevation={0} className='bg-success text-center' sx={{mb: '1rem', padding: '1rem'}}>
                  <h3 style={{justifyContent: 'center'}}>Automatische Erklärung <Tooltip title={this.state.report.file.tooltip}><HelpOutlineOutlinedIcon className='text-secondary' fontSize="small" /></Tooltip></h3>
                  <Button 
                    variant="contained" 
                    color="success"
                    disabled={!this.state.report.file.available} 
                    href={this.state.report.file.url} 
                    startIcon={<CloudDownloadIcon />} 
                    size="small"
                    sx={{my: '0.5rem'}}>Erklärung herunterladen</Button>
                  {!this.state.report.file.available && <p className='text-emphasize'><small>Ergänze <NavLink to="/company" className="text-underline">deine Steuerdaten</NavLink>, um die Datei zu generieren.</small></p>}
                </Paper>
                <p className='text-center'>
                <Button
                    variant="contained"
                    onClick={this.props.completion}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Fertigstellen
                  </Button>
                  <Button
                    onClick={() => this.setState(prevState => ({...prevState, activeStep: prevState.activeStep - 1 }))}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Zurück
                  </Button></p>
              </StepContent>
            </Step> }

          </Stepper></> : <>

            <Paper elevation={0} className='bg-info' sx={{padding: '1rem'}}>
              <p className='text-emphasize'><strong>Du willst jederzeit sehen, was in der UVA anzuführen ist?</strong></p>
              <p><small>Dann sichere dir jetzt ein Premium Angebot.</small></p>
              <Button sx={{mt: '1rem'}} component={NavLink} to="/products" size="small" variant="contained" color="info">Premium werden</Button>
            </Paper>

            <OrDivider />

            <Paper elevation={0} className='bg-light' sx={{padding: '1rem'}}>
              <p className='text-emphasize'><strong>Alternativ kannst du diese UVA einmalig freischalten</strong></p>
              <p><small>Kosten: 1% vom UVA Ergebnis (min. {i18n.t('currency_label', { val: 10.0, currency: "eur" })})</small></p>
              { this.state.report && this.state.report.price != this.state.report.to_pay && <p><small>Bereits bezahlt: {i18n.t('currency_label', { val: this.state.report.price - this.state.report.to_pay, currency: "eur" })}</small></p>}
              <Button sx={{mt: '1rem'}} onClick={this.purchase} size="small" variant="outlined" color="primary" disabled={this.state.report?.payable != true}>{ this.state.report?.payable == true ? `Für ${i18n.t('currency_label', { val: this.state.report?.to_pay, currency: "eur" })} freischalten` : `Freischalten ab ${dayjs.unix(this.state.report?.payable_at).utc().format('DD. MMMM YYYY')} möglich` }</Button>
            </Paper>

          </>}       

          </> : <>
            <Skeleton variant="rounded" width="100%" height={100} />
            <Divider />
            <Skeleton variant="rounded" width="100%" height={100} />
          </>
        }
      </>
    );
  }
};

export default Report;