import React, { useState } from 'react';
import FormUtils from "../../utils/FormUtils";
import { Button, FormGroup, TextField, MenuItem } from '@mui/material';
import { VatIdField } from "../Shared/FormItems";

const TaxSettings = (props) => {

  const [didChange, setDidChange] = useState(false);

  const onChange = () => {
    setDidChange(true);
  }

  return (
    <form method="put" action="/company" onChange={onChange} onSubmit={(e) => { FormUtils.onFormSubmit(e, props.completion); setDidChange(false); }}>
      <FormGroup>
        <TextField name="tax_id" label="Steuernummer" defaultValue={props.tax_id} variant="filled" />
      </FormGroup>

      <FormGroup>
        <VatIdField defaultValue={props.vat_id} />
      </FormGroup>

      <FormGroup>
        <TextField
          name="default_currency" label="Währung" defaultValue={props.default_currency}
          variant="filled"
          select
          disabled
        >
          <MenuItem key="eur" value="eur">
            EUR (€)
          </MenuItem>
        </TextField>
      </FormGroup>

      <FormGroup row={true}>
        <Button sx={{mr: 'auto'}} variant="contained" color="primary" type="submit" disabled={!didChange}>Speichern</Button>
      </FormGroup>
    </form>
  );
};

export default TaxSettings;